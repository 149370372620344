import React, { useState, useEffect, Fragment } from "react";
import {
  TextField,
  TextStyle,
  Spinner,
  Button,
  Select,
  Popover,
  Checkbox,
  FormLayout,
} from "@shopify/polaris";
import axios from "../Assets/Lib/axios";
import closeImage from "../Assets/Images/close-popup.svg";

const EditProfileDetail = (props) => {
  const {
    ref,
    title,
    requestId,
    parameter,
    result,
    toastMarkup,
    handleClose,
    isLoading,
    setIsLoading,
    handleClosePopupWithChanges,
  } = props;
  const accreditationTypeId = result?.accreditation_type_id;

  const [profiles, setProfiles] = useState();
  const [profileDetails, setProfileDetails] = useState();
  const [profileZones, setProfileZones] = useState();
  const [profileVenues, setProfileVenues] = useState();
  const [profileServices, setProfileServices] = useState();
  const [profileBelongings, setProfileBelongings] = useState();
  const [profileOrganizations, setProfileOrganizations] = useState();
  const [profileCategories, setProfileCategories] = useState([]);
  const [profileFunctions, setProfileFunctions] = useState();
  const [profileRules, setProfileRules] = useState([]);
  const [selectedProfileValue, setSelectedProfileValue] = useState();

  const [nonAccreditationCategories, setNonAccreditationCategories] =
    useState();
  const [nonAccreditationFanCategories, setNonAccreditationFanCategories] =
    useState();
  const [nonAccreditationFunctions, setNonAccreditationFunctions] = useState();

  const [inputOrganizationValue, setInputOrganizationValue] = useState(
    result?.profile_organizations
  );
  const [organizationInputType, setOrganizationInputType] = useState();

  const isAccreditation = result?.accreditation_type?.is_accreditation;

  const [value, setValue] = useState({
    profile_id: result?.profile_id,
    profile_zones: result?.profile_zones,
    profile_venues: result?.profile_venues,
    // profile_services: result?.profile_services,
    profile_belongings: result?.profile_belongings,
    profile_organizations: result?.profile_organizations,
    ...(isAccreditation === 0 && {
      media_category:
        result?.organisation?.role?.media_accreditation_subcategory?.en
          .toLowerCase()
          .replace(/\s+/g, "_"),
      organisation_role: result?.organisation?.role?.id,
    }),
  });

  // const [profileIdValue, setProfileIdValue] = useState(result?.profile_id);

  useEffect(() => {
    if (parameter === "profile_information") {
      fetchProfileDetails();
    }
  }, []);

  useEffect(() => {
    if (selectedProfileValue) {
      const {
        zones,
        venues,
        services,
        belongings,
        organizations,
        categories,
        functions,
      } = profileDetails[selectedProfileValue.key];

      setProfileZones(zones);
      setProfileVenues(venues);
      setProfileServices(services);
      setProfileBelongings(belongings);
      setProfileOrganizations(organizations);
      setProfileCategories(categories);
      setProfileFunctions(functions);

      setOrganizationInputType(organizations?.length > 0 ? "dropdown" : "text");

      // setValue("media_category", "");
      // setValue("profile_zones", "");
      // setValue("profile_venues", "");
      // setValue("profile_services", "");
      // setValue("profile_belongings", "");
      // setValue("profile_organizations", "");
    }
  }, [selectedProfileValue]);
  useEffect(() => {
    if (result?.profile_venues) {
      setSelectedVenues(result.profile_venues);
    }
  }, [result?.profile_venues]);
  useEffect(() => {
    if (result?.profile_belongings) {
      setSelectedBelongings(result.profile_belongings);
    }
  }, [result?.profile_belongings]);

  const fetchProfileDetails = () => {
    setIsLoading(true);
    axios
      .get(`admin/v1/registration-requests/profile-details`, {
        params: {
          accreditationTypeId,
        },
      })
      .then((res) => {
        const {
          profiles,
          profileDetails,
          profileRules,
          categories,
          fanCategories,
          functions,
        } = res?.data?.data?.profileListDetails;

        setProfiles(profiles);
        setProfileDetails(profileDetails);
        setProfileRules(profileRules);

        setNonAccreditationCategories(categories);
        setNonAccreditationFanCategories(fanCategories);
        setNonAccreditationFunctions(functions);

        setSelectedProfileValue(
          profiles.find((option) => +option.value === +result?.profile_id)
        );

        const pId = profiles.find((el) => +el.value === result?.profile_id);
        const savedFunction = Object.entries(profileDetails[pId.key].functions)
          .flatMap(([key, value]) => {
            return value.map((item) => ({ key, ...item }));
          })
          .find((item) => +item.value === +result?.organisation?.role?.id);

        const savedCategory = Object.entries(profileDetails[pId.key].categories)
          .map(([value, label]) => ({ value, label }))
          .find((i) => i.value === savedFunction.key);

        setValue({
          ...value,
          media_category: savedCategory.value,
          organisation_role: savedFunction.value,
        });

        setIsLoading(false);
      })
      .catch((err) => console.log(""))
      .finally(() => setIsLoading(false));
  };
  const [venuesPopoverActive, setVenuesPopoverActive] = useState(false);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [belongingPopoverActive, setBelongingPopoverActive] = useState(false);
  const [selectedBelongings, setSelectedBelongings] = useState([]);

  const handleBelongingCheckbox = (newValue, belongingValue) => {
    setSelectedBelongings((prevState) =>
      newValue
        ? [...prevState, belongingValue]
        : prevState.filter((belonging) => belonging !== belongingValue)
    );
  };
  const handleVenuesCheckbox = (newValue, venueValue) => {
    setSelectedVenues((prevState) =>
      newValue
        ? [...prevState, venueValue]
        : prevState.filter((venue) => venue !== venueValue)
    );
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const form_data = new FormData();
    form_data.append("profile_information", true);

    if (isAccreditation === 1) {
      form_data.append("profile_id", selectedProfileValue.value);
      form_data.append("profile_zones", value.profile_zones);
      profileRules?.venues?.fieldDisplayType === "List"
        ? selectedVenues.forEach((venue) => {
            form_data.append("profile_venues[]", venue);
          })
        : form_data.append("profile_venues", value.profile_venues);

      // form_data.append("profile_services", value.profile_services);
      // form_data.append("profile_belongings", value.profile_belongings);
      selectedBelongings.forEach((belonging) => {
        form_data.append("profile_belongings[]", belonging);
      });
      form_data.append(
        "profile_organizations",
        organizationInputType === "text"
          ? inputOrganizationValue
          : value.profile_organizations
      );
    }

    form_data.append("organisation_role", value.organisation_role);

    form_data.append("_method", "PATCH");

    axios
      .post(`admin/v1/registration-requests/${requestId}/edit`, form_data)
      .then((res) => {
        handleClosePopupWithChanges();
      })
      .catch((err) => console.log(""))
      .finally((e) => setIsLoading(false));
  };

  return (
    <>
      <div ref={ref}>
        <FormLayout>
          <div>
            <div className="rowDiv">
              <div className="popupText">
                <TextStyle variation="strong">{title}</TextStyle>
              </div>

              <img
                src={closeImage}
                onClick={handleClose}
                style={{
                  width: "25px",
                  marginLeft: "auto",
                  marginRight: "0",
                  cursor: "pointer",
                }}
              ></img>
            </div>
          </div>

          <div className="row">
            {isAccreditation === 1 && (
              <Fragment>
                {profiles && (
                  <div className="col-sm-4 pb-2">
                    <div className="popupText">
                      <TextStyle variation="strong">Profile</TextStyle>

                      <Select
                        name="profile_id"
                        value={String(value?.profile_id)}
                        options={profiles.map(({ value, label, key }) => {
                          return { value, label, key };
                        })}
                        onChange={(selectedValue) => {
                          setValue({
                            ...value,
                            profile_id: selectedValue,
                          });
                          setSelectedProfileValue(
                            profiles.find(
                              (option) => option.value === selectedValue
                            )
                          );
                        }}
                        placeholder="Please select"
                      />
                    </div>
                  </div>
                )}

                {selectedProfileValue && profileZones?.length > 0 && (
                  <div className="col-sm-4 pb-2">
                    <div className="popupText">
                      <TextStyle variation="strong">Zone</TextStyle>
                      <Select
                        name="profile_zones"
                        value={String(value?.profile_zones)}
                        options={profileZones.map(({ value, label, key }) => {
                          return { value, label, key };
                        })}
                        onChange={(selectedValue) => {
                          setValue({
                            ...value,
                            profile_zones: selectedValue,
                          });
                        }}
                        placeholder="Please select"
                      />
                    </div>
                  </div>
                )}

                {selectedProfileValue && profileVenues?.length > 0 && (
                  <div className="col-sm-4 pb-2">
                    <div className="popupText venues-selection">
                      <TextStyle variation="strong">Venue</TextStyle>
                      {profileRules?.venues?.fieldDisplayType === "List" ? (
                        <Popover
                          active={venuesPopoverActive}
                          activator={
                            <Button
                              onClick={() =>
                                setVenuesPopoverActive(!venuesPopoverActive)
                              }
                              style={{ width: 100 }}
                            >
                              Select Venue
                            </Button>
                          }
                          onClose={() => setVenuesPopoverActive(false)}
                        >
                          <Popover.Section>
                            {profileVenues.map(({ value, label, key }) => (
                              <Checkbox
                                key={key}
                                checked={selectedVenues.includes(value)}
                                label={label}
                                onChange={(newValue) =>
                                  handleVenuesCheckbox(newValue, value)
                                }
                              />
                            ))}
                          </Popover.Section>
                        </Popover>
                      ) : (
                        <Select
                          name="profile_venues"
                          value={String(value?.profile_venues)}
                          options={profileVenues.map(
                            ({ value, label, key }) => {
                              return { value, label, key };
                            }
                          )}
                          onChange={(selectedValue) => {
                            setValue({
                              ...value,
                              profile_venues: selectedValue,
                            });
                          }}
                          placeholder="Please select"
                        />
                      )}
                    </div>
                  </div>
                )}

                {selectedProfileValue && profileBelongings?.length > 0 && (
                  <div className="col-sm-4 pb-2">
                    <div className="popupText belonging-selection">
                      <TextStyle variation="strong">Belonging</TextStyle>

                      <Popover
                        active={belongingPopoverActive}
                        activator={
                          <Button
                            onClick={() =>
                              setBelongingPopoverActive(!belongingPopoverActive)
                            }
                          >
                            Select Belongings
                          </Button>
                        }
                        onClose={() => setBelongingPopoverActive(false)}
                      >
                        <Popover.Section>
                          {profileBelongings.map(({ value, label, key }) => (
                            <Checkbox
                              key={key}
                              checked={selectedBelongings.includes(value)}
                              label={label}
                              onChange={(newValue) =>
                                handleBelongingCheckbox(newValue, value)
                              }
                            />
                          ))}
                        </Popover.Section>
                      </Popover>
                    </div>
                  </div>
                )}

                {selectedProfileValue && (
                  <div className="col-sm-4 pb-2">
                    <div className="popupText">
                      <TextStyle variation="strong">Organization</TextStyle>
                      {profileOrganizations?.length > 0 ? (
                        <Select
                          name="profile_organizations"
                          value={String(value?.profile_organizations)}
                          options={[
                            {
                              value: "",
                              label: "Please select",
                              class: "default-option",
                            },
                            ...(profileOrganizations || []).map(
                              ({ value, label, key }) => {
                                return { value, label, key };
                              }
                            ),
                          ]}
                          onChange={(selectedValue) => {
                            setValue({
                              ...value,
                              profile_organizations: selectedValue,
                            });
                          }}
                          // placeholder="Please select"
                        />
                      ) : (
                        <TextField
                          value={String(inputOrganizationValue)}
                          onChange={(inputValue) =>
                            setInputOrganizationValue(inputValue)
                          }
                          autoComplete="off"
                        />
                      )}
                    </div>
                  </div>
                )}

                {selectedProfileValue && (
                  <div className="col-sm-4 pb-2">
                    <div className="popupText">
                      <TextStyle variation="strong">Category</TextStyle>
                      <Select
                        name="media_category"
                        value={String(value?.media_category)}
                        options={[
                          {
                            value: "",
                            label: "Please select",
                            class: "default-option",
                          },
                          ...(Object.entries(profileCategories) || [])?.map(
                            ([value, label]) => {
                              return { value, label };
                            }
                          ),
                        ]}
                        onChange={(selectedValue) => {
                          // console.log(selectedValue);
                          setValue({
                            ...value,
                            media_category: selectedValue,
                          });
                        }}
                        // placeholder="Please select"
                      />
                    </div>
                  </div>
                )}

                {selectedProfileValue && value.media_category && (
                  <div className="col-sm-4 pb-2">
                    <div className="popupText">
                      <TextStyle variation="strong">Role</TextStyle>
                      <Select
                        name="organisation_role"
                        value={String(value?.organisation_role)}
                        options={[
                          {
                            value: "",
                            label: "Please select",
                            class: "default-option",
                          },
                          ...(
                            profileFunctions[value.media_category] || []
                          )?.map(({ value, label, key }) => {
                            return { value, label, key };
                          }),
                        ]}
                        onChange={(selectedValue) => {
                          setValue({
                            ...value,
                            organisation_role: selectedValue,
                          });
                        }}
                        // placeholder="Please select"
                      />
                    </div>
                  </div>
                )}

                {/* No need because single value */}
                {/* <div className="col-sm-4 pb-2">
              {selectedProfileValue && profileServices?.length > 0 && (
                <div className="popupText">
                  <TextStyle variation="strong">Service</TextStyle>
                  <Select
                    name="profile_services"
                    value={String(value?.profile_services)}
                    options={profileServices.map(({ value, label, key }) => {
                      return { value, label, key };
                    })}
                    onChange={(selectedValue) => {
                      setValue({
                        ...value,
                        profile_services: selectedValue,
                      });
                    }}
                    placeholder="Please select"
                  />
                </div>
              )}
            </div> */}
              </Fragment>
            )}

            {nonAccreditationCategories && isAccreditation === 0 && (
              <Fragment>
                <div className="col-sm-4 pb-2">
                  <div className="popupText">
                    <TextStyle variation="strong">Category</TextStyle>
                    <Select
                      name="media_category"
                      value={String(value?.media_category)}
                      options={[
                        {
                          value: "",
                          label: "Please select",
                          class: "default-option",
                        },
                        ...(
                          Object.entries(nonAccreditationCategories) || []
                        )?.map(([value, label]) => {
                          return { value, label };
                        }),
                      ]}
                      onChange={(selectedValue) => {
                        setValue({
                          ...value,
                          media_category: selectedValue,
                        });
                      }}
                      // placeholder="Please select"
                    />
                  </div>
                </div>

                {nonAccreditationFunctions && (
                  <div className="col-sm-4 pb-2">
                    <div className="popupText">
                      <TextStyle variation="strong">Role</TextStyle>
                      <Select
                        name="organisation_role"
                        value={value?.organisation_role}
                        options={[
                          {
                            value: "",
                            label: "Please select",
                            class: "default-option",
                          },
                          ...(
                            nonAccreditationFunctions[value.media_category] ||
                            []
                          )?.map(({ value, label, key }) => {
                            return { value, label, key };
                          }),
                        ]}
                        onChange={(selectedValue) => {
                          setValue({
                            ...value,
                            organisation_role: parseInt(selectedValue),
                          });
                        }}
                        // placeholder="Please select"
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>

          <Button onClick={handleSubmit}>
            {isLoading ? <Spinner size="small" /> : "Submit"}
          </Button>
        </FormLayout>
      </div>
      {toastMarkup}
    </>
  );
};

export default EditProfileDetail;
