import React, { useCallback, useState, useEffect, useRef, Fragment } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  Loading,
  TextStyle,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Button,
  Icon
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import rowMail from "../../Assets/Images/row-mail.svg";
import rowAccept from "../../Assets/Images/row-accept.svg";
import rowReject from "../../Assets/Images/row-reject.svg";
import viewImg from "../../Assets/Images/view.svg";

import flagRed from "../../Assets/Images/flag-red.svg";
import flag from "../../Assets/Images/flag.svg";
import Cookies from "js-cookie";
import filterGrey from "../../Assets/Images/filter-grey.svg";
import filterOrange from "../../Assets/Images/filter-orange.svg";
import FilterCloseButton from "../../Components/FilterCloseButton";
import FilterParentDiv from "../../Components/FilterParentDiv";
import { useNavigate } from "react-router-dom";
import { isDefined, adminRole } from "../../Helpers";
import RequestPopup from "../../Components/RequestPopup";
import { countryList } from "../../Assets/countries";
import Dropdown from 'react-bootstrap/Dropdown';
import {
  MobileVerticalDotsMajor
} from "@shopify/polaris-icons";
const TableOfrequests = (props) => {
  const [popupActive, setPopupActive] = useState(false);
  const navigate = useNavigate();
  const [ts, setTs] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const refClickedItem = useRef(null);
  const refReferenceNumber = useRef(null);
  const refOrgCountry = useRef(null);
  const refName = useRef(null);
  const refArrivalDate = useRef(null);
  const refChannel = useRef(
    Cookies.get("vmc-requests-filter-state") === "channel_tag" &&
    isDefined(Cookies.get("vmc-requests-search-value")) &&
    Cookies.get("vmc-requests-search-value")
  );
  const refEmail = useRef(null);
  const refOrganisationRole = useRef(null);
  const refOrganisation = useRef(null);
  const refNationality = useRef(null);
  const refDocumentNumber = useRef(null);
  const refAccreditationType = useRef(
    Cookies.get("vmc-requests-filter-state") === "accreditation_id" &&
    isDefined(Cookies.get("vmc-requests-search-value")) &&
    Cookies.get("vmc-requests-search-value")
  );
  const refCreatedAt = useRef(null);
  const refStatus = useRef(
    (Cookies.get("vmc-requests-filter-state") === "status" ||
      Cookies.get("vmc-requests-filter-state") === "hayya_status") &&
    isDefined(Cookies.get("vmc-requests-search-value")) &&
    Cookies.get("vmc-requests-search-value")
  );
  const refHayyaStatus = useRef(
    Cookies.get("vmc-requests-filter-state") === "hayya_status" &&
    isDefined(Cookies.get("vmc-requests-search-value")) &&
    Cookies.get("vmc-requests-search-value")
  );
  const format = "YY-MM-DD HH:mm";
  const reference = useRef("");
  const reference2 = useRef("");
  const reference3 = useRef("");
  const mailIdRef = useRef("");
  const perPage = 100;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [requestTotal, setRequestTotal] = useState(0);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(
    isDefined(Cookies.get("vmc-requests-filter-page"))
      ? parseInt(Cookies.get("vmc-requests-filter-page"))
      : 1
  );
  const [sortState, setSortState] = useState("-id");
  const [filterState, setFilterState] = useState(
    isDefined(Cookies.get("vmc-requests-filter-state"))
      ? Cookies.get("vmc-requests-filter-state")
      : ""
  );
  const handleChangePage = (event, value) => {
    Cookies.set("vmc-requests-filter-page", value);
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const [searchValue, setSearchValue] = useState(
    isDefined(Cookies.get("vmc-requests-search-value"))
      ? Cookies.get("vmc-requests-search-value")
      : ""
  );
  useEffect(() => {
    console.log("testy=C", typeof (Cookies.get("vmc-requests-search-value")));
    getData();
  }, [page, filterState, searchValue, ts]);
  const [isHovered, setIsHovered] = useState(false);
  const handleCancel = () => {
    setActiveEmail(false);
  };
  const [emailMessageValue, setEmailMessageValue] = useState("");

  const [emailMessageError, setEmailMessageError] = useState("");
  const handleChangeEmailMessage = (newValue) => {
    setEmailMessageValue(newValue);
    setEmailMessageError("");
  };
  const handleSend = () => {
    if (!emailMessageValue) {
      setEmailMessageError("Please enter a message");
    } else {
      setIsSendingEmail(true);
      const form_data = new FormData();

      form_data.append("message", emailMessageValue);
      form_data.append("_method", "PATCH");

      axios
        .post(
          `admin/v1/registration-requests/${mailIdRef.current}/email`,
          form_data
        )
        .then((res) => {
          setActiveEmail(false);
          setIsSendingEmail(false);
          setToastContent("Email Sent Successfully");
          toggleActiveToast();
          setTs(+new Date());
        })
        .catch((err) => console.log(""));
    }
  };

  const handleFlag = (e) => {
    // isFlagged ? setIsFlagged(false) : setIsFlagged(true);
    const form_data = new FormData();
    form_data.append("_method", "PATCH");
    form_data.append(
      "is_flagged",
      !JSON.parse(e.currentTarget.id).is_flagged ? 1 : 0
    );
    axios
      .post(
        `admin/v1/registration-requests/${JSON.parse(e.currentTarget.id).id
        }/flag`,
        form_data
      )
      .then((res) => {
        setTs(+new Date());
        //getData();
      })
      .catch((err) => console.log(""));
  };
  const switchStatusColor = (status) => {
    switch (status) {
      case "pending":
      case "pending_sc":
      case "pending_gco":
      case "pending_gco":
      case "pending_qna":
        return "#4B4B4B";

      case "approved":
      case "approved_sc":
      case "approved_gco":
      case "approved_qna":
      case "approved_lusail":
        return "#00CFB7";
      case "rejected":
      case "rejected_sc":
      case "rejected_gco":
      case "rejected_qna":
      case "rejected_lusail":
        return "#B85555";
      case "updated":
        return "#FFBF00";
      case "new":
        return "#FF4700";
      default:
      // code block
    }
  };

  const [activeEmail, setActiveEmail] = useState(false);
  const handleEmail = (e) => {
    mailIdRef.current = JSON.parse(e.currentTarget.id).id;
    setActiveEmail(true);
  };

  const switchParam = (param) => {
    switch (param) {
      case "0":
        return "all-requests";
      case "1":
        return "gco-requests";
      case "2":
        return "qna-requests";
      case "3":
        return "hayya-requests";
      case "4":
        return "gco-requests-imo";
      default:
      // code block
    }
  };
  const canApproveOrReject = (param) => {
    let boolApproveAndRejection = false;
    if (adminRole !== "Content" || adminRole !== "Viewer") {
      if (props.selectedTab === "0") {
        if (
          JSON.parse(param).status.toLowerCase() !== "rejected_sc" &&
          JSON.parse(param).status.toLowerCase() !== "approved_sc" &&
          (JSON.parse(param).status.toLowerCase() === "new" ||
            JSON.parse(param).status.toLowerCase() === "pending" ||
            JSON.parse(param).status.toLowerCase() === "updated")
        ) {
          boolApproveAndRejection = true;
        } else {
          boolApproveAndRejection = false;
        }
      }
      if (props.selectedTab === "1" || props.selectedTab === "4") {
        if (
          JSON.parse(param).status_level.toLowerCase() === "gco" &&
          // JSON.parse(param).accreditation_id !== 1 &&
          JSON.parse(param).status !== "rejected_gco" &&
          JSON.parse(param).status !== "approved_gco"
        ) {
          boolApproveAndRejection = true;
        } else {
          boolApproveAndRejection = false;
        }
      }

      if (props.selectedTab === "2") {
        if (
          JSON.parse(param).status.toLowerCase() === "approved_gco" &&
          // JSON.parse(param).accreditation_id !== 1 &&
          JSON.parse(param).status !== "rejected_qna" &&
          JSON.parse(param).status !== "approved_qna"
        ) {
          boolApproveAndRejection = true;
        } else {
          boolApproveAndRejection = false;
        }
      }
      if (props.selectedTab === "3") {
        boolApproveAndRejection = false;
      }
      return boolApproveAndRejection;
    }
  };
  const switchAcceditationType = (param) => {
    switch (param) {
      case 2:
        return "FIFA";
      case 3:
        return "HC";
      case 4:
        return "Lusail";
      default:
      // code block
    }
  };
  const [showRequestPopup, setShowRequestPopup] = useState(false);
  const handleExport = () => {
    setPopupActive(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}admin/v1/users/export/${props.selectedTab === "0"
          ? "sc"
          : props.selectedTab === "1"
            ? "gco"
            : props.selectedTab === "2"
              ? "qna"
              : props.selectedTab === "4"
                ? "imo"
                : "hayya"
        }${searchValue
          ? `?filter[${filterState}]=${searchValue.toLowerCase() !== "all"
            ? searchValue.toLowerCase()
            : ""
          }`
          : ""
        }`
      )
      .then((result) => {
        setPopupActive(false);
        setToastContent("Please check your email inbox in a few");
        toggleActiveToast();
      })
      .catch((err) => console.log(err));

    // window.open(
    //   `${process.env.REACT_APP_BASE_URL}admin/v1/users/export/${
    //     props.selectedTab === "0"
    //       ? "sc"
    //       : props.selectedTab === "1"
    //       ? "gco"
    //       : props.selectedTab === "2"
    //       ? "qna"
    //       : "hayya"
    //   }?per_page=${page}&page=${page}${
    //     searchValue
    //       ? `&filter[${filterState}]=${
    //           searchValue.toLowerCase() !== "all"
    //             ? searchValue.toLowerCase()
    //             : ""
    //         }`
    //       : ""
    //   }`
    // );
  };
  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays + 1;
  }

  const checkDateOfArrival = (param) => {
    let str = "";
    if (moment(param) === new Date()) {
      str = "Today";
    } else {
      str = getNumberOfDays(new Date(), param);
    }

    return str;
  };
  const isBetween = (param, a, b) => {
    if (param >= a && param <= b) {
      return true;
    } else return false;
  };
  const arrivalBgd = (param) => {
    let color = "";
    if (
      moment(param).format("YYYY-MM-DD") ===
      moment(new Date()).format("YYYY-MM-DD") ||
      isBetween(getNumberOfDays(new Date(), param), 1, 5)
    ) {
      color = "var(--maroon)";
    } else if (moment(param) < new Date()) {
      color = "#F0F6FF";
    } else if (isBetween(getNumberOfDays(new Date(), param), 5, 10)) {
      color = "#FFE0B2";
    } else if (getNumberOfDays(new Date(), param) >= 10) {
      color = "#f0f0f0";
    }

    return color;
  };

  const greyBackgroundCondition = (param) => {
    let bool = false;
    if (props.selectedTab === "0") {
      if (param === "new") {
        bool = true;
      }
    }
    if (props.selectedTab === "1" || props.selectedTab === "4") {
      if (param === "approved_sc") {
        bool = true;
      }
    }
    if (props.selectedTab === "2") {
      if (param === "approved_gco") {
        bool = true;
      }
    }
    return bool;
  };
  const greyBackgroundColor = "rgba(196, 196, 188, 0.3)";
  const refBoolPage = useRef(true);
  const getData = () => {
    filterState === "arrival_date" &&
      setSearchValue(moment(searchValue).format("MM/DD/YYYY"));
    axios
      .get(
        `admin/v1/${switchParam(props.selectedTab)}?per_page=${perPage}&page=${refBoolPage.current ? page : 1
        }${searchValue && filterState
          ? `&filter[${filterState}]=${searchValue.toLowerCase() !== "all"
            ? searchValue.toLowerCase()
            : ""
          }`
          : ""
        }`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);


        setRequestTotal(result.data.data.total);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        let array = [];
        if (result?.data?.data?.data?.length)
          for (var i = 0; i < result?.data?.data?.data.length; i++) {
            const item = result?.data?.data?.data[i];
            array.push([
              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.reference_number ? (
                  item?.reference_number
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.first_name || item?.last_name ? (
                  `${item.first_name && item.first_name} ${item.last_name && item.last_name
                  }`
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.document_number ? (
                  item?.document_number
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.email ? (
                  item?.email
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {props.selectedTab !== "3" ? (
                  item?.organisation?.role?.type?.en ? (
                    item?.organisation?.role?.type?.en
                  ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                  )
                ) : props.selectedTab === "3" ? (
                  item?.hayya_response?.response?.data?.fanIdNo ? (
                    item?.hayya_response?.response?.data?.fanIdNo
                  ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                  )
                ) : (
                  ""
                )}
              </div>,
              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.additional_data?.date_of_arrival &&
                  (props.selectedTab === "0" || props.selectedTab === "3") ? (
                  <p
                    className="arrivalDiv mediumFont"
                    style={{
                      backgroundColor: arrivalBgd(
                        item?.additional_data?.date_of_arrival
                      ),
                      fontSize:
                        moment(
                          new Date(item?.additional_data?.date_of_arrival)
                        ).format("YYYY-MM-DD") ===
                        moment(new Date()).format("YYYY-MM-DD") && "1.2rem",
                      color:
                        moment(
                          new Date(item?.additional_data?.date_of_arrival)
                        ).format("YYYY-MM-DD") ===
                          moment(new Date()).format("YYYY-MM-DD") ||
                          isBetween(
                            getNumberOfDays(
                              new Date(),
                              item?.additional_data?.date_of_arrival
                            ),
                            1,
                            5
                          )
                          ? "white"
                          : "#005CE8",
                    }}
                  >
                    {moment(item?.additional_data?.date_of_arrival).format(
                      "YYYY-MM-DD"
                    ) !== moment(new Date()).format("YYYY-MM-DD")
                      ? checkDateOfArrival(
                        item?.additional_data?.date_of_arrival
                      )
                      : "Today"}
                  </p>
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.nationality &&
                  (props.selectedTab === "1" || props.selectedTab === "4") ? (
                  findArrayElementByCode(countries, String(item?.nationality))
                    .en
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.organisation?.name ? (
                  item?.organisation?.name
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.organisation?.country ? (
                  item?.organisation?.country
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {props.selectedTab !== "3" && item.created_at ? (
                  <div>
                    {moment(item.created_at).format(format).split(" ")[0]}
                    <span>&nbsp;&nbsp;</span>
                    {moment(item.created_at).format(format).split(" ")[1]}
                  </div>
                ) : props.selectedTab === "3" &&
                  item.hayya_response?.created_at ? (
                  <div>
                    {
                      moment(item.hayya_response?.created_at)
                        .format(format)
                        .split(" ")[0]
                    }
                    <span>&nbsp;&nbsp;</span>
                    {
                      moment(item.hayya_response?.created_at)
                        .format(format)
                        .split(" ")[1]
                    }
                  </div>
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                  color:
                    parseInt(item?.accreditation_id) === 2
                      ? "#005391"
                      : parseInt(item?.accreditation_id) === 3
                        ? "#550065"
                        : "#00671d",
                }}
              >
                {item?.accreditation_id !== null &&
                  parseInt(item.accreditation_id) !== 1 ? (
                  switchAcceditationType(parseInt(item.accreditation_id))
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  color:
                    props.selectedTab !== "3"
                      ? switchStatusColor(item.status)
                      : switchHayyaStatusColor(item),

                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {props.selectedTab === "3" ? (
                  switchHayyaStatus(item)
                ) : item?.status ? (
                  searchValue === "approved_imo" ||
                    searchValue === "rejected_imo" ||
                    props.selectedTab === "4" ? (
                    (
                      item?.status.charAt(0).toUpperCase() +
                      item?.status.slice(1)
                    )
                      .replace("_", " ")
                      .replace("-", " ")
                      .replace("sc", "SC")
                      .replace("gco", "IMO")
                      .replace("qna", "QNA")
                  ) : (
                    (
                      item?.status.charAt(0).toUpperCase() +
                      item?.status.slice(1)
                    )
                      .replace("_", " ")
                      .replace("-", " ")
                      .replace("sc", "SC")
                      .replace("gco", "GCO")
                      .replace("qna", "QNA")
                  )
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${!greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                  }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,

                  color: String(item.channel_tag) === "512" ? "#9f9f9e" : "red",
                }}
              >
                {item?.channel_tag !== null && props.selectedTab === "3" ? (
                  switchChannel(String(item.channel_tag))
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              // adminRole() !== "Content" && adminRole() !== "Viewer" ? (
              <div
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                <div
                  className="rowDiv actionButtons"
                  // id={`icons_${item.id}`}
                  style={{
                    // visibility: "hidden",
                    padding: "0.9rem"
                  }}
                >
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <Icon
                        source={MobileVerticalDotsMajor}
                        style={{ margin: "0 5px" }}
                        color="subdued"
                      ></Icon>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item >
                     <div className="parentImageLeft">
                       <img
                          src={rowAccept}
                          className="imageLeft"
                          onClick={(e) =>
                            canApproveOrReject(JSON.stringify(item)) &&
                            handleRowApprove(e)
                          }
                          id={JSON.stringify(item)}
                          style={{
                            opacity: canApproveOrReject(JSON.stringify(item))
                              ? 1
                              : 0.3,
                            cursor:
                              canApproveOrReject(JSON.stringify(item)) && "pointer",
                          }}
                        /> Approve
                      </div></Dropdown.Item>
                      <Dropdown.Item>
                      <div className="parentImageLeft">
                        <img
                          src={rowReject}
                          className="imageLeft"
                          onClick={(e) =>
                            canApproveOrReject(JSON.stringify(item)) &&
                            handleRowReject(e)
                          }
                          id={JSON.stringify(item)}
                          style={{
                            opacity: canApproveOrReject(JSON.stringify(item))
                              ? 1
                              : 0.3,
                            cursor:
                              canApproveOrReject(JSON.stringify(item)) && "pointer",
                          }}
                        /> Reject
                      </div></Dropdown.Item>
                      <Dropdown.Item>
                        <div className="parentImageLeft">
                        <img
                          className="imageLeft"
                          style={{
                            cursor: "pointer",
                            opacity:
                              props.selectedTab === "0" || props.selectedTab === "3"
                                ? "1"
                                : "0.3",
                            pointerEvents:
                              props.selectedTab === "0" || props.selectedTab === "3"
                                ? "auto"
                                : "none",
                            visibility:
                              (adminRole() === "Content" ||
                                adminRole() === "Viewer") &&
                              "hidden",
                          }}
                          id={JSON.stringify(item)}
                          src={rowMail}
                          onClick={(e) => handleEmail(e)}
                        /> Message
                      </div></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <div className="parentImageLeft">
                    <img
                      src={rowAccept}
                      className="imageLeft"
                      onClick={(e) =>
                        canApproveOrReject(JSON.stringify(item)) &&
                        handleRowApprove(e)
                      }
                      id={JSON.stringify(item)}
                      style={{
                        opacity: canApproveOrReject(JSON.stringify(item))
                          ? 1
                          : 0.3,
                        cursor:
                          canApproveOrReject(JSON.stringify(item)) && "pointer",
                      }}
                    />
                  </div>
                  <div className="parentImageLeft">
                    <img
                      src={rowReject}
                      className="imageLeft"
                      onClick={(e) =>
                        canApproveOrReject(JSON.stringify(item)) &&
                        handleRowReject(e)
                      }
                      id={JSON.stringify(item)}
                      style={{
                        opacity: canApproveOrReject(JSON.stringify(item))
                          ? 1
                          : 0.3,
                        cursor:
                          canApproveOrReject(JSON.stringify(item)) && "pointer",
                      }}
                    />
                  </div>
                  <div className="parentImageLeft">
                    <img
                      className="imageLeft"
                      style={{
                        cursor: "pointer",
                        opacity:
                          props.selectedTab === "0" || props.selectedTab === "3"
                            ? "1"
                            : "0.3",
                        pointerEvents:
                          props.selectedTab === "0" || props.selectedTab === "3"
                            ? "auto"
                            : "none",
                        visibility:
                          (adminRole() === "Content" ||
                            adminRole() === "Viewer") &&
                          "hidden",
                      }}
                      id={JSON.stringify(item)}
                      src={rowMail}
                      onClick={(e) => handleEmail(e)}
                    />
                  </div> */}
                </div>
              </div>,
              // ) : (
              //   <p style={{ visibility: "hidden" }}>empty</p>
              // ),

              <div
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  className="flag"
                  // id={`icons_${item.id}`}
                  style={{
                    visibility: item.is_flagged ? "visible" : "hidden",
                  }}
                >
                  <div className="parentImageLeft">
                    <img
                      className="imageLeft flagImg"
                      style={{
                        cursor: "pointer",
                        visibility:
                          (adminRole() === "Content" ||
                            adminRole() === "Viewer") &&
                          "hidden",
                      }}
                      src={item.is_flagged ? flagRed : flag}
                      onClick={(e) => handleFlag(e)}
                      id={JSON.stringify(item)}
                    />
                  </div>
                </div>
                <div className="flag">
                  <div className="parentImageLeft">
                    <img
                      src={viewImg}
                      className="imageLeft"
                      id={JSON.stringify(item)}
                      style={{ width: "20px", cursor: "pointer" }}
                      onClick={handleChangeShowPopup}
                    />
                  </div>
                </div>
              </div>,
            ]);
          }
        array.unshift([
          <div
            id="firstTableItem0"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField2"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField2Label"
                  aria-invalid="false"
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") ===
                      "reference_number"
                      ? searchValue
                      : ""
                  }
                  onChange={handleChangeReferenceSearchValue}
                  ref={refReferenceNumber}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="reference_number"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refReferenceNumber}
                ></FilterCloseButton>

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItem1"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField2"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField2Label"
                  aria-invalid="false"
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") === "name"
                      ? searchValue
                      : ""
                  }
                  onChange={handleChangeNameSearchValue}
                  ref={refName}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="name"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refName}
                ></FilterCloseButton>

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItem13"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField2"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField2Label"
                  aria-invalid="false"
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") ===
                      "document_number"
                      ? searchValue
                      : ""
                  }
                  onChange={handleChangeDocumentNumberSearchValue}
                  ref={refDocumentNumber}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="document_number"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refDocumentNumber}
                ></FilterCloseButton>

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItem2"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField4"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField4Label"
                  aria-invalid="false"
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") === "email"
                      ? searchValue
                      : ""
                  }
                  onChange={handleChangeEmailSearchValue}
                  ref={refEmail}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="email"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refEmail}
                ></FilterCloseButton>
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItem3"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
              // style={{
              //   visibility: props.selectedTab === "3" ? "hidden" : "visible",
              // }}
              >
                <input
                  id="PolarisTextField6"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField6Label"
                  aria-invalid="false"
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") ===
                      "organisation_role"
                      ? searchValue
                      : ""
                  }
                  onChange={handleChangeRoleSearchValue}
                  ref={refOrganisationRole}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition={
                    props.selectedTab === "3" ? "fanIdNo" : "organisation_role"
                  }
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refOrganisationRole}
                ></FilterCloseButton>
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItem14"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{
                  visibility:
                    props.selectedTab !== "0" &&
                    props.selectedTab !== "3" &&
                    "hidden",
                }}
              >
                <input
                  id="PolarisTextField14"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField14Label"
                  aria-invalid="false"
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") === "arrival_date"
                      ? searchValue
                      : ""
                  }
                  onChange={handleChangeArrivalDateSearchValue}
                  type="date"
                  ref={refArrivalDate}
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItem10"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            {props.selectedTab === "1" || props.selectedTab === "4" ? (
              <FilterParentDiv filterClicked={filterClicked}>
                <div
                  className="Polaris-TextField"
                // style={{ visibility: "hidden" }}
                >
                  <select
                    id="PolarisSelect2"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeNationality}
                    defaultValue={
                      Cookies.get("vmc-requests-filter-state") === "status"
                        ? searchValue
                        : ""
                    }
                    ref={refNationality}
                  >
                    {countries.map((item) => (
                      <option value={item.code} key={item.code}>
                        {item.en}
                      </option>
                    ))}
                  </select>
                  <div className="Polaris-Select__Content" aria-hidden="true">
                    <span className="Polaris-Select__SelectedOption">
                      {nationalityValue === ""
                        ? "All"
                        : findArrayElementByCode(countries, nationalityValue)
                          .en}
                    </span>
                    <span className="Polaris-Select__Icon">
                      <span className="Polaris-Icon">
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div className="Polaris-Select__Backdrop" />
                </div>
              </FilterParentDiv>
            ) : (
              <FilterParentDiv filterClicked={filterClicked}>
                <div
                  className="Polaris-TextField"
                  style={{ visibility: "hidden" }}
                >
                  <input
                    id="PolarisTextField18"
                    autoComplete="off"
                    className="Polaris-TextField__Input"
                    aria-labelledby="PolarisTextField18Label"
                    aria-invalid="false"
                    defaultValue={searchValue}
                  />
                  <div className="Polaris-TextField__Backdrop" />
                </div>
              </FilterParentDiv>
            )}
          </div>,
          <div
            id="firstTableItem4"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField8"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField8Label"
                  aria-invalid="false"
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") === "organisation"
                      ? searchValue
                      : ""
                  }
                  onChange={handleChangeOrganisationSearchValue}
                  ref={refOrganisation}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="organisation"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refOrganisation}
                ></FilterCloseButton>
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItem12"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            {/* {props.selectedTab === "1" || props.selectedTab === "4" ? ( */}
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
              // style={{ visibility: "hidden" }}
              >
                <select
                  id="PolarisSelect2"
                  className="Polaris-Select__Input"
                  aria-invalid="false"
                  onChange={handleChangeOrgCountry}
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") === "status"
                      ? searchValue
                      : ""
                  }
                  ref={refOrgCountry}
                >
                  {countryList.map((item, index) => (
                    <option value={item.name} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {orgCountryValue === ""
                      ? "All"
                      : findArrayElementByName(countries, orgCountryValue).en}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
            {/* ) : (
              <FilterParentDiv filterClicked={filterClicked}>
                <div
                  className="Polaris-TextField"
                  style={{ visibility: "hidden" }}
                >
                  <input
                    id="PolarisTextField18"
                    autoComplete="off"
                    className="Polaris-TextField__Input"
                    aria-labelledby="PolarisTextField18Label"
                    aria-invalid="false"
                    defaultValue={searchValue}
                  />
                  <div className="Polaris-TextField__Backdrop" />
                </div>
              </FilterParentDiv>
            )} */}
          </div>,

          <div
            id="firstTableItem5"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{
                  visibility: props.selectedTab === "3" ? "hidden" : "visible",
                }}
              >
                <input
                  id="PolarisTextField14"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField14Label"
                  aria-invalid="false"
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") === "created_at"
                      ? searchValue
                      : ""
                  }
                  onChange={handleChangeTimeOfRegistrationSearchValue}
                  type="date"
                  ref={refCreatedAt}
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItem6"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-Select">
                <select
                  id="PolarisSelect3"
                  className="Polaris-Select__Input"
                  aria-invalid="false"
                  onChange={handleChangeTypeSearchValue}
                  defaultValue={
                    Cookies.get("vmc-requests-filter-state") ===
                      "accreditation_id"
                      ? searchValue
                      : ""
                  }
                  ref={refAccreditationType}
                >
                  <option value="All">All</option>
                  <option value="4">Lusail</option>
                  <option value="3">HC</option>
                  <option value="2">FIFA</option>
                  <option value="1">Non Accredited</option>
                </select>
                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {!refAccreditationType.current
                      ? "All"
                      : switchType(typeSearchValue)}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItem7"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-Select"
              // style={{
              //   visibility: props.selectedTab === "3" ? "hidden" : "visible",
              // }}
              >
                {props.selectedTab === "3" && (
                  <select
                    id="PolarisSelect2"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeStatusSearchValue}
                    defaultValue={
                      Cookies.get("vmc-requests-filter-state") === "status"
                        ? searchValue
                        : ""
                    }
                    ref={refHayyaStatus}
                  >
                    <option value="All">All</option>
                    <option value="error">MOI Error</option>
                    <option value="New">New</option>
                    <option value="Pending">Pending MOI</option>
                    <option value="Pending Entry Visa">Pending Entry Visa</option>
                    <option value="Approved">MOI Approved</option>
                    <option value="Rejected">MOI Rejected</option>
                    <option value="400">Hayya Error</option>
                    <option value="0">No Status</option>
                  </select>
                )}
                {props.selectedTab === "0" && (
                  <select
                    id="PolarisSelect2"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeStatusSearchValue}
                    defaultValue={
                      Cookies.get("vmc-requests-filter-state") === "status"
                        ? searchValue
                        : ""
                    }
                    ref={refStatus}
                  >
                    <option value="All">All</option>
                    <option value="New">New</option>
                    <option value="Updated">Updated</option>
                    <option value="Pending">Pending</option>
                    {/* <option value="approved_non_accredited">Approved</option> */}
                    <option value="approved_sc">Approved SC</option>
                    <option value="approved_gco">Approved GCO</option>
                    {/* <option value="approved_gco">Approved IMO</option> */}
                    <option value="approved">Approved QNA</option>
                    <option value="approved_imo">Approved IMO</option>
                    <option value="rejected_sc">Rejected SC</option>
                    <option value="rejected_gco">Rejected GCO</option>
                    <option value="rejected_imo">Rejected IMO</option>
                    {/* <option value="rejected_gco">Rejected IMO</option> */}
                    <option value="rejected_qna">Rejected QNA</option>
                  </select>
                )}
                {props.selectedTab === "1" && (
                  <select
                    id="PolarisSelect2"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeStatusSearchValue}
                    defaultValue={
                      Cookies.get("vmc-requests-filter-state") === "status"
                        ? searchValue
                        : ""
                    }
                    ref={refStatus}
                  >
                    <option value="All">All</option>
                    <option value="approved_sc">Approved SC</option>
                    <option value="approved_gco">Approved GCO</option>
                    <option value="rejected_gco">Rejected GCO</option>
                  </select>
                )}
                {props.selectedTab === "2" && (
                  <select
                    id="PolarisSelect2"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeStatusSearchValue}
                    defaultValue={
                      Cookies.get("vmc-requests-filter-state") === "status"
                        ? searchValue
                        : ""
                    }
                    ref={refStatus}
                  >
                    <option value="All">All</option>
                    <option value="approved_gco">Approved GCO</option>
                    <option value="approved">Approved QNA</option>
                    <option value="rejected_qna">Rejected QNA</option>
                  </select>
                )}
                {props.selectedTab === "4" && (
                  <select
                    id="PolarisSelect2"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeStatusSearchValue}
                    defaultValue={
                      Cookies.get("vmc-requests-filter-state") === "status"
                        ? searchValue
                        : ""
                    }
                    ref={refStatus}
                  >
                    <option value="All">All</option>
                    <option value="approved_sc">Approved SC</option>
                    <option value="approved_imo">Approved IMO</option>
                    <option value="rejected_imo">Rejected IMO</option>
                  </select>
                )}

                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {!refStatus.current
                      ? "All"
                      : switchStatus(statusSearchValue)}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItem11"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{ visibility: props.selectedTab !== "3" && "hidden" }}
              >
                <div className="Polaris-Select">
                  <select
                    id="PolarisSelect3"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeChannelValue}
                    defaultValue={
                      Cookies.get("vmc-requests-filter-state") === "channel_tag"
                        ? searchValue
                        : ""
                    }
                    ref={refChannel}
                  >
                    <option value="All">All</option>
                    <option value="0">Default</option>
                    <option value="1">Backoffice</option>
                    <option value="2">ServiceCenter</option>
                    <option value="8">B2B</option>
                    <option value="16">Liferay</option>
                    <option value="32">Mob</option>
                    <option value="64">IFES</option>
                    <option value="128">CustomerPortal</option>
                    <option value="256">QMatic</option>
                    <option value="512">VMC</option>
                    <option value="1024">MOFA</option>
                    <option value="2048">MDO</option>
                    <option value="513">Done</option>
                    <option value="514">Not Updated</option>
                    <option value="515">Updated</option>
                  </select>
                  <div className="Polaris-Select__Content" aria-hidden="true">
                    <span className="Polaris-Select__SelectedOption">
                      {refChannel.current === "All" || !refChannel.current
                        ? "All"
                        : switchChannel(String(channelValue))}
                    </span>
                    <span className="Polaris-Select__Icon">
                      <span className="Polaris-Icon">
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div className="Polaris-Select__Backdrop" />
                </div>
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItem9"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{ visibility: "hidden" }}
              >
                <input
                  id="PolarisTextField18"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField18Label"
                  aria-invalid="false"
                  defaultValue={searchValue}
                />
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          // <div
          //   id="firstTableItem10"
          //   style={{ display: filterClicked ? "block" : "none" }}
          // >
          //   <FilterParentDiv filterClicked={filterClicked}>
          //     <div
          //       className="Polaris-TextField"
          //       style={{ visibility: "hidden" }}
          //     >
          //       <input
          //         id="PolarisTextField18"
          //         autoComplete="off"
          //         className="Polaris-TextField__Input"
          //         aria-labelledby="PolarisTextField18Label"
          //         aria-invalid="false"
          //         defaultValue={searchValue}
          //       />
          //       <div className="Polaris-TextField__Backdrop" />
          //     </div>
          //   </FilterParentDiv>
          // </div>,
          <div
            id="firstTableItem8"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <Button onClick={handleExport}>Export</Button>
            </FilterParentDiv>
          </div>,
        ]);

        setItems(array);

        props.selectedTab === "0" &&
          props.onChangerequestsNumber(
            result?.data?.data?.data
              .filter(({ status }) => status.toLowerCase().includes("new"))
              .reduce((accumulator) => accumulator + 1, 0)
          );
        props.selectedTab === "1" &&
          props.onChangerequestsNumber(
            result?.data?.data?.data
              .filter(({ status }) =>
                status.toLowerCase().includes("approved_sc")
              )
              .reduce((accumulator) => accumulator + 1, 0)
          );
      })
      .catch((err) => setIsLoading(false));
  };
  const switchHayyaStatusColor = (item) => {
    let str = "";
    if (
      item?.hayya_status === "Data Error" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = "#ff0000";
    } else if (item?.hayya_status === "New") {
      str = "#FF4700";
    } else if (item?.hayya_status === "Pending") {
      str = "#4B4B4B";
    } else if (item?.hayya_status === "Approved") {
      str = "#00CFB7";
    } else if (
      item?.hayya_status === "Rejected" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = "#B85555";
    } else if (item?.hayya_status === "400") {
      str = "#ff0000";
    }

    return str;
  };
  const switchHayyaStatus = (item) => {
    let str = "";

    if (
      item?.hayya_status === "Data Error" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = (
        <div>
          <p>MOI Error</p>
          {/* <br /> */}
          {item?.hayya_message && (
            <p className="tableMessage">
              {item?.hayya_message?.replaceAll("[", "").replaceAll("]", "")}
            </p>
          )}
        </div>
      );
    } else if (item?.hayya_status === "New") {
      str = "New";
    } else if (
      item?.hayya_status === "Rejected" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = (
        <div>
          <p>MOI Rejected</p>
          {/* <br /> */}
          {item?.hayya_message && (
            <p className="tableMessage">
              {item?.hayya_message?.replaceAll("[", "").replaceAll("]", "")}
            </p>
          )}
        </div>
      );
    } else if (item?.hayya_status === "Pending") {
      str = "Pending MOI";
    } else if (item?.hayya_status === "Pending Entry Visa") {
      str = "Pending Entry Visa";
    } else if (item?.hayya_status === "Approved") {
      str = "MOI Approved";
    } else if (item?.hayya_status === "400") {
      str = (
        <div>
          <p>Hayya Error</p>
          {/* <br /> */}
          {item?.hayya_response?.response?.message && (
            <p className="tableMessage">
              {item?.hayya_response?.response?.message}
            </p>
          )}
          {!item?.hayya_response?.response?.message
            && item?.hayya_response?.response?.errors
            && item?.hayya_response?.response?.errors[
            Object.keys(item?.hayya_response?.response?.errors)[0]
            ] && (
              <p className="tableMessage">
                {
                  item?.hayya_response?.response?.errors[
                  Object.keys(item?.hayya_response?.response?.errors)[0]
                  ]
                }
              </p>
            )}
        </div>
      );
    } else if (item?.hayya_status === null) {
      str = "";
    }

    let eligibilityErrorStr = item?.hayya_logs_check_eligibility_message === '' ? '' : <div>
      <p>Eligibility Error</p>
      <p className="tableMessage">
        {item?.hayya_logs_check_eligibility_message?.replaceAll("[", "").replaceAll("]", "")}
      </p>
    </div>;

    return <Fragment>{eligibilityErrorStr}{str}</Fragment>;
  };
  const switchStatus = (param) => {
    if (props.selectedTab === "3")
      switch (param) {
        case "error":
          return "MOI Error";
        case "New":
          return "New";
        case "0":
          return "No Status";
        case "Pending":
          return "Pending MOI";
        case "Pending Entry Visa":
          return "Pending Entry Visa";
        case "Approved":
          return "MOI Approved";
        case "Rejected":
          return "MOI Rejected";
        case "400":
          return "Hayya Error";
        case "":
          return "All";
        case "All":
          return "All";
        default:
          return null;
        // code block
      }
    else
      switch (param) {
        case "New":
          return "New";
        case "Updated":
          return "Updated";
        case "Rejected":
          return "Rejected";
        case "approved":
          return "Approved QNA";
        case "approved_imo":
          return "Approved IMO";
        case "rejected_qna":
          return "Rejected QNA";
        case "Pending":
          return "Pending";
        case "approved_sc":
          return "Approved SC";
        case "approved_gco":
          return "Approved GCO";
        case "rejected_sc":
          return "Rejected SC";
        case "rejected_gco":
          return "Rejected GCO";
        case "rejected_imo":
          return "Rejected IMO";
        case "approved_non_accredited":
          return "Approved";
        case "":
          return "All";
        case "All":
          return "All";
        default:
          return null;
        // code block
      }
  };

  const switchChannel = (param) => {
    console.log("channel=", param);
    switch (param) {
      case "0":
        return "Default";
      case "1":
        return "Backoffice";
      case "2":
        return "ServiceCenter";
      case "8":
        return "B2B";
      case "16":
        return "Liferay";
      case "32":
        return "Mob";
      case "64":
        return "IFES";
      case "128":
        return "CustomerPortal";
      case "256":
        return "QMatic";
      case "512":
        return "VMC";
      case "1024":
        return "MOFA";
      case "2048":
        return "MDO";
      case "All":
        return "All";
      case "513":
        return "Done";
      case "514":
        return "Not Updated";
      case "515":
        return "Updated";
      // code block
    }
  };
  const switchType = (param) => {
    switch (param) {
      case "1":
        return "Non Accredited";
      case "2":
        return "FIFA";
      case "3":
        return "HC";
      case "4":
        return "Lusail";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const handleDeleteTextField = (param) => {
    param.current.value = "";
    setSearchValue("");
    refBoolPage.current = true;
    Cookies.set("vmc-requests-search-value", "");
  };

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  const [clickedItem, setClickedItem] = useState(null);
  const handleRowClick = (e) => {
    if (adminRole() !== "Content") {
      if (props.selectedTab === "0") {
        window.location.href.indexOf("/admin/users") > -1
          ? window.open(
            `/admin/users/completed-applications/${JSON.parse(e.currentTarget.id).id
            }`,
            "_blank"
          )
          : window.open(
            `/admin/requests/sc-requests/${JSON.parse(e.currentTarget.id).id
            }`,
            "_blank"
          );
        // window.location.href.indexOf("/admin/users") > -1
        //   ? navigate(
        //       `/admin/users/completed-applications/${
        //         JSON.parse(e.currentTarget.id).id
        //       }`
        //     )
        //   : navigate(
        //       `/admin/requests/sc-requests/${JSON.parse(e.currentTarget.id).id}`
        //     );
      }

      if (props.selectedTab === "1") {
        window.open(
          `/admin/requests/gco-requests/${JSON.parse(e.currentTarget.id).id}`,
          "_blank"
        );
        // navigate(
        //   `/admin/requests/gco-requests/${JSON.parse(e.currentTarget.id).id}`
        // );
      }
      if (props.selectedTab === "2") {
        window.open(
          `/admin/requests/qna-requests/${JSON.parse(e.currentTarget.id).id}`,
          "_blank"
        );
        // navigate(
        //   `/admin/requests/qna-requests/${JSON.parse(e.currentTarget.id).id}`
        // );
      }
      if (props.selectedTab === "3") {
        window.open(
          `/admin/requests/hayya-requests/${JSON.parse(e.currentTarget.id).id}`,
          "_blank"
        );
        // navigate(
        //   `/admin/requests/hayya-requests/${JSON.parse(e.currentTarget.id).id}`
        // );
      }
      if (props.selectedTab === "4") {
        window.open(
          `/admin/requests/imo-requests/${JSON.parse(e.currentTarget.id).id}`,
          "_blank"
        );
        // navigate(
        //   `/admin/requests/hayya-requests/${JSON.parse(e.currentTarget.id).id}`
        // );
      }
      // setClickedItem(JSON.parse(e.currentTarget.id));
    }
  };
  const [active, setActive] = useState(false);
  const handleChangeReasonOfRejection = useCallback(
    (newValue) => setReasonOfRejectionValue(newValue),
    []
  );

  const [rejectClicked, setRejectClicked] = useState(false);
  const [reasonOfRejectionValue, setReasonOfRejectionValue] = useState("");

  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActiveToast}
      duration="10000"
    />
  ) : null;
  const [activeAreYouSure, setActiveAreYouSure] = useState(false);
  const [questionValue, setQuestionValue] = useState(false);
  const handleNo = () => {
    setActiveAreYouSure(false);
  };
  const handleRowApprove = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "approve";
    reference2.current =
      JSON.parse(e.currentTarget.id).accreditation_id +
      "_" +
      JSON.parse(e.currentTarget.id).status_level;
    reference3.current = JSON.parse(
      e.currentTarget.id
    ).hayya_response?.response?.data?.fanIdNo;

    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to approve this request?");
  };
  const handleRowReject = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "reject";
    reference2.current =
      JSON.parse(e.currentTarget.id).accreditation_id +
      "_" +
      JSON.parse(e.currentTarget.id).status_level;
    reference3.current = JSON.parse(
      e.currentTarget.id
    ).hayya_response?.response?.data?.fanIdNo;
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to reject this request?");
  };
  const handleYes = () => {
    const form_data = new FormData();
    form_data.append(
      "status",
      reference.current.split("_")[1] === "approve" ? "approved" : "rejected"
    );
    reference.current.split("_")[1] === "reject" &&
      form_data.append("reason_of_rejection", reasonOfRejectionValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${reference.current.split("_")[0]
        }/status${props.selectedTab === "0" &&
          parseInt(reference2.current.split("_")[0]) !== 1 &&
          reference2.current.split("_")[1] === "SC"
          ? "/sc"
          : ""
        }${props.selectedTab === "1" ? "/gco" : ""}${props.selectedTab === "4" ? "/gco" : ""
        }${props.selectedTab === "2" ? "/qna" : ""}`,
        form_data
      )
      .then((res) => {
        // if (props.selectedTab === "2") {
        //   let str = "";
        //   if (reference3.current) str = "/status/update/hayya";
        //   else str = "/status/hayya";
        //   axios
        //     .patch(
        //       `admin/v1/registration-requests/${
        //         reference.current.split("_")[0]
        //       }${str}`
        //     )
        //     .then((res) => {
        //       setRejectClicked(false);
        //       setActive(false);
        //       setActiveAreYouSure(false);
        //       setToastContent(
        //         reference.current.split("_")[1] === "approve"
        //           ? "Request Approved Successfully"
        //           : "Request Rejected Successfully"
        //       );
        //       toggleActiveToast();
        //       setTs(+new Date());
        //     })
        //     .catch((err) => console.log(""));
        // }
        // Cookies.set("sumPendingrequests", "");
        // else {

        if (res?.data?.status) {
          setRejectClicked(false);
          setActive(false);
          setActiveAreYouSure(false);
          setToastContent(
            reference.current.split("_")[1] === "approve"
              ? "Request Approved Successfully"
              : "Request Rejected Successfully"
          );
          toggleActiveToast();
          setTs(+new Date());
        } else {
          setToastContent(res?.data?.message);
          setActiveAreYouSure(false);
          setTs(+new Date());
          toggleActiveToast();
        }

        // }
      })
      .catch((err) => console.log(""));
  };

  const [selectedSubTab, setSelectedSubTab] = useState(0);

  const handleTabChangeSubTab = useCallback(
    (selectedTabIndex) => setSelectedSubTab(selectedTabIndex),
    []
  );

  const subTabs = [
    {
      id: "profile",
      content: (
        <p style={{ marginLeft: "2rem", marginRight: "2rem" }}>Profile</p>
      ),
      accessibilityLabel: "Profile",
    },
    {
      id: "organization",
      content: "Organization",
    },
    {
      id: "media",
      content: "Media",
    },
  ];

  const resetValues = (param) => {
    param !== "refDocumentNumber" && (refDocumentNumber.current.value = "");
    param !== "refArrivalDate" && (refArrivalDate.current.value = "");
    param !== "refReferenceNumber" && (refReferenceNumber.current.value = "");
    param !== "refOrgCountry" && (refOrgCountry.current.value = "");
    param !== "refName" && (refName.current.value = "");
    param !== "refEmail" && (refEmail.current.value = "");
    param !== "refOrganisationRole" && (refOrganisationRole.current.value = "");
    param !== "refOrganisation" && (refOrganisation.current.value = "");
    param !== "refAccreditationType" && (refAccreditationType.current = "");
    // param !== "ref6" && (ref6.current.value = "");
    param !== "refCreatedAt" && (refCreatedAt.current.value = "");
    param !== "refStatus" && (refStatus.current = "");
    param !== "refHayyaStatus" && (refHayyaStatus.current = "");
    param !== "refNationality" && (refNationality.current = "");
    param !== "refChannel" && (refChannel.current = "");
  };
  const handleChangeReferenceSearchValue = (event) => {
    resetValues("refReferenceNumber");
    setFilterState("reference_number");
    Cookies.set("vmc-requests-filter-state", "reference_number");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };

  const handleChangeNameSearchValue = (event) => {
    resetValues("refName");
    setFilterState("name");
    Cookies.set("vmc-requests-filter-state", "name");

    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };
  const handleChangeDocumentNumberSearchValue = (event) => {
    resetValues("refDocumentNumber");
    setFilterState("document_number");
    Cookies.set("vmc-requests-filter-state", "document_number");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };
  const handleChangeEmailSearchValue = (event) => {
    resetValues("refEmail");
    setFilterState("email");
    Cookies.set("vmc-requests-filter-state", "email");

    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };
  const handleChangeRoleSearchValue = (event) => {
    resetValues("refOrganisationRole");
    setFilterState(props.selectedTab !== "3" ? "organisation_role" : "fanIdNo");
    Cookies.set(
      "vmc-requests-filter-state",
      props.selectedTab !== "3" ? "organisation_role" : "fanIdNo"
    );

    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };
  const handleChangeOrganisationSearchValue = (event) => {
    resetValues("refOrganisation");
    setFilterState("organisation");
    Cookies.set("vmc-requests-filter-state", "organisation");

    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };
  const handleChangeNationalitySearchValue = (event) => {
    resetValues("refAccreditationType");
    setFilterState("nationality");
    Cookies.set("vmc-requests-filter-state", "nationality");

    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };
  // const handleChangeContactNumberSearchValue = (event) => {
  //   resetValues("ref6");
  //   setFilterState("phone");
  //   setSearchValue(event.target.value);
  // };
  const handleChangeTimeOfRegistrationSearchValue = (event) => {
    resetValues("refCreatedAt");
    setFilterState("created_at");
    Cookies.set("vmc-requests-filter-state", "created_at");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };
  const handleChangeArrivalDateSearchValue = (event) => {
    resetValues("refArrivalDate");
    setFilterState("arrival_date");
    Cookies.set("vmc-requests-filter-state", "arrival_date");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
  };
  const [statusSearchValue, setStatusSearchValue] = useState(
    isDefined(Cookies.get("vmc-requests-search-value")) &&
      (Cookies.get("vmc-requests-filter-state") === "status" ||
        Cookies.get("vmc-requests-filter-state") === "hayya_status")
      ? Cookies.get("vmc-requests-search-value")
      : "All"
  );
  const [statusHayyaSearchValue, setStatusHayyaSearchValue] = useState(
    isDefined(Cookies.get("vmc-requests-search-value"))
      ? Cookies.get("vmc-requests-search-value")
      : "All"
  );
  const [nationalityValue, setNationalityValue] = useState("");
  const [orgCountryValue, setOrgCountryValue] = useState("");
  const handleChangeStatusSearchValue = (event) => {
    resetValues("refStatus");
    refStatus.current = event.target.value;
    props.selectedTab === "3"
      ? setFilterState("hayya_status")
      : setFilterState("status");

    props.selectedTab === "3"
      ? Cookies.set("vmc-requests-filter-state", "hayya_status")
      : Cookies.set("vmc-requests-filter-state", "status");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
    setStatusSearchValue(event.target.value);
  };
  const handleChangeHayyaStatusSearchValue = (event) => {
    resetValues("refHayyaStatus");
    refHayyaStatus.current = event.target.value;
    setFilterState("hayya_status");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
    setStatusHayyaSearchValue(event.target.value);
  };
  const handleChangeNationality = (event) => {
    resetValues("refNationality");
    refNationality.current = event.target.value;
    setFilterState("nationality");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
    setNationalityValue(event.target.value);
  };
  const handleChangeOrgCountry = (event) => {
    resetValues("refOrgCountry");
    refOrgCountry.current = event.target.value;
    setFilterState("organisation_country");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
    setOrgCountryValue(event.target.value);
    console.log(event.target.value)
  };
  const [typeSearchValue, setTypeSearchValue] = useState(
    isDefined(Cookies.get("vmc-requests-search-value")) &&
      Cookies.get("vmc-requests-filter-state") === "accreditation_id"
      ? Cookies.get("vmc-requests-search-value")
      : "All"
  );
  const handleChangeTypeSearchValue = (event) => {
    resetValues("refAccreditationType");
    refAccreditationType.current = event.target.value;
    setFilterState("accreditation_id");
    Cookies.set("vmc-requests-filter-state", "accreditation_id");

    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);

    setTypeSearchValue(event.target.value);
  };

  const [channelValue, setChannelValue] = useState(
    isDefined(Cookies.get("vmc-requests-search-value")) &&
      Cookies.get("vmc-requests-filter-value") === "channel_tag"
      ? String(Cookies.get("vmc-requests-search-value"))
      : "All"
  );
  const handleChangeChannelValue = (event) => {
    resetValues("refChannel");
    refChannel.current = event.target.value;
    setFilterState("channel_tag");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    Cookies.set("vmc-requests-filter-state", "channel_tag");
    refBoolPage.current = false;
    Cookies.set("vmc-requests-search-value", event.target.value);
    setChannelValue(event.target.value !== "All" ? event.target.value : "");
  };

  const array = [
    "firstTableItem0",
    "firstTableItem1",
    "firstTableItem2",
    "firstTableItem3",
    "firstTableItem4",
    "firstTableItem5",
    "firstTableItem6",
    "firstTableItem7",
    "firstTableItem8",
    "firstTableItem9",
    "firstTableItem10",
    "firstTableItem11",
    "firstTableItem12",
    "firstTableItem13",
    "firstTableItem14",
  ];
  const [filterClicked, setFilterClicked] = useState(true);
  const handleFilter = () => {
    if (!filterClicked) {
      for (var i = 0; i < array.length; i++) {
        if (document.getElementById(array[i])) {
          document.getElementById(array[i]).style.display = "block";
        }
      }
    } else {
      resetValues("refOrgCountry");
      resetValues("refReferenceNumber");
      resetValues("refName");
      resetValues("refEmail");
      resetValues("refOrganisationRole");
      resetValues("refOrganisation");
      resetValues("refAccreditationType");
      resetValues("refCreatedAt");
      resetValues("refStatus");
      resetValues("refHayyaStatus");
      resetValues("refChannel");
      resetValues("refDocumentNumber");
      resetValues("refArrivalDate");
      setSearchValue("");
      refBoolPage.current = true;
      Cookies.set("vmc-requests-search-value", "");

      for (var i = 0; i < array.length; i++) {
        document.getElementById(array[i]).style.display = "none";
      }
    }
    Cookies.set(
      "vmc-requests-filter-clicked",
      !filterClicked === true ? "true" : "false"
    );
    setFilterClicked(!filterClicked);
  };
  const [selected, setSelected] = useState("today");

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const countries = [
    {
      code: "",
      ar: "All",
      en: "All",
    },
    {
      code: "004",
      ar: "افغانستان",
      en: "Afghanistan",
    },
    {
      code: "008",
      ar: "البانيا",
      en: "Albania",
    },
    {
      code: "012",
      ar: "الجزائر",
      en: "Algeria",
    },
    {
      code: "016",
      ar: "ساموا الامريكية",
      en: "American Samoa",
    },
    {
      code: "020",
      ar: "اندورا",
      en: "Andorra",
    },
    {
      code: "024",
      ar: "انجولا",
      en: "Angola",
    },
    {
      code: "660",
      ar: "جزيرة أنجويلا",
      en: "Anguilla",
    },
    {
      code: "028",
      ar: "انتيجوا و باربودا",
      en: "Antigua and Barbuda",
    },
    {
      code: "032",
      ar: "الارجنتين",
      en: "Argentina",
    },
    {
      code: "051",
      ar: "ارمينيا",
      en: "Armenia",
    },
    {
      code: "533",
      ar: "أروبا",
      en: "Aruba",
    },
    {
      code: "036",
      ar: "استراليا",
      en: "Australia",
    },
    {
      code: "040",
      ar: "النمسا",
      en: "Austria",
    },
    {
      code: "031",
      ar: "اذريبيجان",
      en: "Azerbaijan",
    },
    {
      code: "044",
      ar: "بهاماس​",
      en: "Bahamas",
    },
    {
      code: "048",
      ar: "البحرين",
      en: "Bahrain",
    },
    {
      code: "050",
      ar: "بنغلاديش​",
      en: "Bangladesh",
    },
    {
      code: "052",
      ar: "باربادوس​",
      en: "Barbados",
    },
    {
      code: "112",
      ar: "بيلروس​",
      en: "Belarus",
    },
    {
      code: "056",
      ar: "بلجيكا",
      en: "Belgium",
    },
    {
      code: "084",
      ar: "بيليز",
      en: "Belize",
    },
    {
      code: "204",
      ar: "بينين",
      en: "Benin",
    },
    {
      code: "060",
      ar: "برمودا",
      en: "Bermuda",
    },
    {
      code: "064",
      ar: "بوتان",
      en: "Bhutan",
    },
    {
      code: "068",
      ar: "بوليفيا",
      en: "Bolivia (Plurinational State of)",
    },
    {
      code: 544,
      ar: "البوسنة والهرسك",
      en: "Bosnia and Herzegovina",
    },
    {
      code: "072",
      ar: "بوتسوانا",
      en: "Botswana",
    },
    {
      code: "074",
      ar: "جزر بوفت",
      en: "Bouvet Island",
    },
    {
      code: "076",
      ar: "البرازيل",
      en: "Brazil",
    },
    {
      code: "086",
      ar: "جزر المحيط الهندي",
      en: "British Indian Ocean Territory",
    },
    {
      code: "096",
      ar: "بروناي دار السلام",
      en: "Brunei Darussalam",
    },
    {
      code: "100",
      ar: "بلغاريا",
      en: "Bulgaria",
    },
    {
      code: "854",
      ar: "بوركينافاسو",
      en: "Burkina Faso",
    },
    {
      code: "108",
      ar: "بروندي",
      en: "Burundi",
    },
    {
      code: "132",
      ar: "جمهوريةالرأس​الاخضر",
      en: "Cabo Verde",
    },
    {
      code: "116",
      ar: "كمبوديا",
      en: "Cambodia",
    },
    {
      code: "120",
      ar: "الكاميرون",
      en: "Cameroon",
    },
    {
      code: "124",
      ar: "كندا",
      en: "Canada",
    },
    {
      code: "136",
      ar: "جزر كيمان",
      en: "Cayman Islands",
    },
    {
      code: "140",
      ar: "افريقيا الوسطي",
      en: "Central African Republic",
    },
    {
      code: "148",
      ar: "تشاد",
      en: "Chad",
    },
    {
      code: "152",
      ar: "تشيلي",
      en: "Chile",
    },
    {
      code: "156",
      ar: "ج.الصين الشعبية",
      en: "China",
    },
    {
      code: "162",
      ar: "جزر كرسمس​",
      en: "Christmas Island",
    },
    {
      code: "166",
      ar: "جزر كوكوس​",
      en: "Cocos (Keeling) Islands",
    },
    {
      code: "170",
      ar: "كولومبيا",
      en: "Colombia",
    },
    {
      code: "174",
      ar: "جمهورية القمرالمتحدة",
      en: "Comoros",
    },
    {
      code: "178",
      ar: "جمهورية الكونغو",
      en: "Congo",
    },
    {
      code: "180",
      ar: "الكونغو الديمقراطية",
      en: "Congo, Democratic Republic of the",
    },
    {
      code: "184",
      ar: "جزر كوك",
      en: "Cook Islands",
    },
    {
      code: "188",
      ar: "كوستاريكا",
      en: "Costa Rica",
    },
    {
      code: "191",
      ar: "كرواتيا",
      en: "Croatia",
    },
    {
      code: "192",
      ar: "كوبا",
      en: "Cuba",
    },
    {
      code: "531",
      ar: "كوراساو",
      en: "Curaأ§ao",
    },
    {
      code: "196",
      ar: "قبرص​",
      en: "Cyprus",
    },
    {
      code: 200,
      ar: "تشيك",
      en: "Czechia",
    },
    {
      code: "384",
      ar: "كوت ديفوار",
      en: "Cأ´te d'Ivoire",
    },
    {
      code: "208",
      ar: "الدنمارك",
      en: "Denmark",
    },
    {
      code: "262",
      ar: "جيبوتي",
      en: "Djibouti",
    },
    {
      code: "212",
      ar: "دومينيكا",
      en: "Dominica",
    },
    {
      code: "214",
      ar: "جمهورية دومنيكان",
      en: "Dominican Republic",
    },
    {
      code: "218",
      ar: "الاكوادور",
      en: "Ecuador",
    },
    {
      code: "818",
      ar: "مصر",
      en: "Egypt",
    },
    {
      code: "222",
      ar: "السالفادور",
      en: "El Salvador",
    },
    {
      code: "226",
      ar: "غينيا الاستوائية",
      en: "Equatorial Guinea",
    },
    {
      code: "080",
      ar: "اريتريا",
      en: "Eritrea",
    },
    {
      code: "233",
      ar: "استونيا",
      en: "Estonia",
    },
    {
      code: "748",
      ar: "سوازيلاند",
      en: "Eswatini",
    },
    {
      code: 230,
      ar: "اثيوبيا",
      en: "Ethiopia",
    },
    {
      code: "238",
      ar: "جزر فوكلند",
      en: "Falkland Islands (Malvinas)",
    },
    {
      code: "234",
      ar: "فارو",
      en: "Faroe Islands",
    },
    {
      code: "242",
      ar: "فيجي",
      en: "Fiji",
    },
    {
      code: "246",
      ar: "فنلندا",
      en: "Finland",
    },
    {
      code: "250",
      ar: "فرنسا",
      en: "France",
    },
    {
      code: "254",
      ar: "غيانا الفرنسية",
      en: "French Guiana",
    },
    {
      code: "258",
      ar: "بولنيسياالفرنسية",
      en: "French Polynesia",
    },
    {
      code: "266",
      ar: "الغابون",
      en: "Gabon",
    },
    {
      code: "270",
      ar: "غامبيا",
      en: "Gambia",
    },
    {
      code: "268",
      ar: "جورجيا",
      en: "Georgia",
    },
    {
      code: 280,
      ar: "المانيا",
      en: "Germany",
    },
    {
      code: "288",
      ar: "غانا",
      en: "Ghana",
    },
    {
      code: "292",
      ar: "جبل طارق",
      en: "Gibraltar",
    },
    {
      code: "300",
      ar: "اليونان",
      en: "Greece",
    },
    {
      code: "304",
      ar: "جرينلند",
      en: "Greenland",
    },
    {
      code: "308",
      ar: "جرينادا",
      en: "Grenada",
    },
    {
      code: "312",
      ar: "جواديلوب",
      en: "Guadeloupe",
    },
    {
      code: "316",
      ar: "غوامي",
      en: "Guam",
    },
    {
      code: "320",
      ar: "جواتيمالا",
      en: "Guatemala",
    },
    {
      code: "831",
      ar: "جزيرة جيزنزي",
      en: "Guernsey",
    },
    {
      code: "324",
      ar: "غينيا",
      en: "Guinea",
    },
    {
      code: "624",
      ar: "غينيا بيساو",
      en: "Guinea-Bissau",
    },
    {
      code: "328",
      ar: "غيانيا",
      en: "Guyana",
    },
    {
      code: "332",
      ar: "هايتي",
      en: "Haiti",
    },
    {
      code: "334",
      ar: "جزر دونالد",
      en: "Heard Island and McDonald Islands",
    },
    {
      code: "336",
      ar: "الفاتيكان",
      en: "Holy See",
    },
    {
      code: "340",
      ar: "هوندوراس​",
      en: "Honduras",
    },
    {
      code: "344",
      ar: "هونج كونج",
      en: "Hong Kong",
    },
    {
      code: "348",
      ar: "المجر",
      en: "Hungary",
    },
    {
      code: "352",
      ar: "ايسلاندا",
      en: "Iceland",
    },
    {
      code: "356",
      ar: "الهند",
      en: "India",
    },
    {
      code: "360",
      ar: "اندونيسيا",
      en: "Indonesia",
    },
    {
      code: "364",
      ar: "ايران",
      en: "Iran (Islamic Republic of)",
    },
    {
      code: "368",
      ar: "العراق",
      en: "Iraq",
    },
    {
      code: "372",
      ar: "ايرلندا",
      en: "Ireland",
    },
    {
      code: "833",
      ar: "جزيرة مان",
      en: "Isle of Man",
    },
    {
      code: "376",
      ar: "اسرائيل",
      en: "Israel",
    },
    {
      code: "380",
      ar: "ايطاليا",
      en: "Italy",
    },
    {
      code: "388",
      ar: "جمايكا",
      en: "Jamaica",
    },
    {
      code: "392",
      ar: "اليابان",
      en: "Japan",
    },
    {
      code: "832",
      ar: "جزيرة جيرزي",
      en: "Jersey",
    },
    {
      code: "400",
      ar: "الاردن",
      en: "Jordan",
    },
    {
      code: "398",
      ar: "كزخستان",
      en: "Kazakhstan",
    },
    {
      code: "404",
      ar: "كينيا",
      en: "Kenya",
    },
    {
      code: "296",
      ar: "كريباتي",
      en: "Kiribati",
    },
    {
      code: "408",
      ar: "كوريا الشمالية",
      en: "Korea (Democratic People's Republic of)",
    },
    {
      code: "410",
      ar: "كوريا الجنوبية",
      en: "Korea, Republic of",
    },
    {
      code: "414",
      ar: "الكويت",
      en: "Kuwait",
    },
    {
      code: "417",
      ar: "كيرغيستان",
      en: "Kyrgyzstan",
    },
    {
      code: "418",
      ar: "لاو",
      en: "Lao People's Democratic Republic",
    },
    {
      code: "428",
      ar: "لاتفيا",
      en: "Latvia",
    },
    {
      code: "422",
      ar: "لبنان",
      en: "Lebanon",
    },
    {
      code: "426",
      ar: "ليسوتو",
      en: "Lesotho",
    },
    {
      code: "430",
      ar: "ليبيريا",
      en: "Liberia",
    },
    {
      code: "434",
      ar: "ليبيا",
      en: "Libya",
    },
    {
      code: "438",
      ar: "ليشتنشين",
      en: "Liechtenstein",
    },
    {
      code: "440",
      ar: "لتوانيا",
      en: "Lithuania",
    },
    {
      code: "442",
      ar: "لكسمبورج",
      en: "Luxembourg",
    },
    {
      code: "446",
      ar: "ماكاو",
      en: "Macao",
    },
    {
      code: "450",
      ar: "مداغاسكر",
      en: "Madagascar",
    },
    {
      code: "454",
      ar: "مالاوي",
      en: "Malawi",
    },
    {
      code: "458",
      ar: "ماليزيا",
      en: "Malaysia",
    },
    {
      code: "462",
      ar: "جزر المالديف",
      en: "Maldives",
    },
    {
      code: "466",
      ar: "مالي",
      en: "Mali",
    },
    {
      code: "470",
      ar: "مالطا",
      en: "Malta",
    },
    {
      code: "584",
      ar: "جزر المرشال",
      en: "Marshall Islands",
    },
    {
      code: "474",
      ar: "مارتنيك",
      en: "Martinique",
    },
    {
      code: "478",
      ar: "موريتانيا",
      en: "Mauritania",
    },
    {
      code: "480",
      ar: "موريشوس​",
      en: "Mauritius",
    },
    {
      code: "175",
      ar: "جزيرة مايوت",
      en: "Mayotte",
    },
    {
      code: "484",
      ar: "المكسيك",
      en: "Mexico",
    },
    {
      code: "583",
      ar: "مكرونسيا",
      en: "Micronesia (Federated States of)",
    },
    {
      code: "498",
      ar: "ملدوفيا",
      en: "Moldova, Republic of",
    },
    {
      code: "492",
      ar: "موناكو",
      en: "Monaco",
    },
    {
      code: "496",
      ar: "منغوليا",
      en: "Mongolia",
    },
    {
      code: "499",
      ar: "الجبل الأسود",
      en: "Montenegro",
    },
    {
      code: "500",
      ar: "مونتسرات",
      en: "Montserrat",
    },
    {
      code: "504",
      ar: "المغرب",
      en: "Morocco",
    },
    {
      code: "508",
      ar: "موزامبيق",
      en: "Mozambique",
    },
    {
      code: "104",
      ar: "ميانمار",
      en: "Myanmar",
    },
    {
      code: "516",
      ar: "ناميبيا",
      en: "Namibia",
    },
    {
      code: "520",
      ar: "نورو",
      en: "Nauru",
    },
    {
      code: "524",
      ar: "نيبال",
      en: "Nepal",
    },
    {
      code: "528",
      ar: "هولندا",
      en: "Netherlands",
    },
    {
      code: "540",
      ar: "نيو كاليدونيا",
      en: "New Caledonia",
    },
    {
      code: "554",
      ar: "نيوزيلاندا",
      en: "New Zealand",
    },
    {
      code: "558",
      ar: "نيكواراجوا",
      en: "Nicaragua",
    },
    {
      code: "562",
      ar: "النيجر",
      en: "Niger",
    },
    {
      code: "566",
      ar: "نيجيريا",
      en: "Nigeria",
    },
    {
      code: "570",
      ar: "نيو",
      en: "Niue",
    },
    {
      code: "574",
      ar: "جزيرة نورفوك",
      en: "Norfolk Island",
    },
    {
      code: "807",
      ar: "جمهورية شمال مقدونيا",
      en: "North Macedonia",
    },
    {
      code: "580",
      ar: "جزر مريانا",
      en: "Northern Mariana Islands",
    },
    {
      code: "578",
      ar: "النورويج",
      en: "Norway",
    },
    {
      code: "512",
      ar: "عمان",
      en: "Oman",
    },
    {
      code: "586",
      ar: "باكستان",
      en: "Pakistan",
    },
    {
      code: "585",
      ar: "بولا",
      en: "Palau",
    },
    {
      code: 999,
      ar: "فلسطين",
      en: "Palestine, State of",
    },
    {
      code: 590,
      ar: "بنما",
      en: "Panama",
    },
    {
      code: "598",
      ar: "بابوا نيو غينيا",
      en: "Papua New Guinea",
    },
    {
      code: "600",
      ar: "باراجواي",
      en: "Paraguay",
    },
    {
      code: "604",
      ar: "بيرو",
      en: "Peru",
    },
    {
      code: "608",
      ar: "الفلبين",
      en: "Philippines",
    },
    {
      code: "612",
      ar: "بيتكيرن",
      en: "Pitcairn",
    },
    {
      code: "616",
      ar: "بولندا",
      en: "Poland",
    },
    {
      code: "620",
      ar: "البرتغال",
      en: "Portugal",
    },
    {
      code: "630",
      ar: "بورتوريكو",
      en: "Puerto Rico",
    },
    {
      code: "634",
      ar: "قطر",
      en: "Qatar",
    },
    {
      code: "642",
      ar: "رومانيا",
      en: "Romania",
    },
    {
      code: "643",
      ar: "روسيا",
      en: "Russian Federation",
    },
    {
      code: "646",
      ar: "رواندا",
      en: "Rwanda",
    },
    {
      code: "638",
      ar: "ريونيون",
      en: "Rأ©union",
    },
    {
      code: "652",
      ar: "سان بارتليمي",
      en: "Saint Barthأ©lemy",
    },
    {
      code: "654",
      ar: "سنت هيلانا",
      en: "Saint Helena, Ascension and Tristan da Cunha[b]",
    },
    {
      code: "659",
      ar: "سانت كيتس​ونيفيس​",
      en: "Saint Kitts and Nevis",
    },
    {
      code: "662",
      ar: "سنت لوسيا",
      en: "Saint Lucia",
    },
    {
      code: "663",
      ar: "سالت مارتن",
      en: "Saint Martin (French part)",
    },
    {
      code: "666",
      ar: "سنت بير وميكليون",
      en: "Saint Pierre and Miquelon",
    },
    {
      code: "670",
      ar: "سنت فينسنت",
      en: "Saint Vincent and the Grenadines",
    },
    {
      code: "882",
      ar: "ساموا",
      en: "Samoa",
    },
    {
      code: "674",
      ar: "سان مارينو",
      en: "San Marino",
    },
    {
      code: "678",
      ar: "سافوتوم و برنسيت",
      en: "Sao Tome and Principe",
    },
    {
      code: "682",
      ar: "السعودية",
      en: "Saudi Arabia",
    },
    {
      code: "686",
      ar: "السنغال",
      en: "Senegal",
    },
    {
      code: "688",
      ar: "صربيا",
      en: "Serbia",
    },
    {
      code: "690",
      ar: "سيشيل",
      en: "Seychelles",
    },
    {
      code: "694",
      ar: "سيراليون",
      en: "Sierra Leone",
    },
    {
      code: "702",
      ar: "سنغافورة",
      en: "Singapore",
    },
    {
      code: "703",
      ar: "سلوفكيا",
      en: "Slovakia",
    },
    {
      code: "705",
      ar: "سلوفنيا",
      en: "Slovenia",
    },
    {
      code: "090",
      ar: "جزر سليمان",
      en: "Solomon Islands",
    },
    {
      code: "706",
      ar: "الصومال",
      en: "Somalia",
    },
    {
      code: "710",
      ar: "جنوب افريقيا",
      en: "South Africa",
    },
    {
      code: "728",
      ar: "جنوب السودان",
      en: "South Sudan",
    },
    {
      code: "724",
      ar: "اسبانيا",
      en: "Spain",
    },
    {
      code: "144",
      ar: "سريلانكا",
      en: "Sri Lanka",
    },
    {
      code: 736,
      ar: "السودان",
      en: "Sudan",
    },
    {
      code: "740",
      ar: "سورينام",
      en: "Suriname",
    },
    {
      code: "744",
      ar: "سفالبارد وجان ماين",
      en: "Svalbard and Jan Mayen[c]",
    },
    {
      code: "752",
      ar: "السويد",
      en: "Sweden",
    },
    {
      code: "756",
      ar: "سويسرا",
      en: "Switzerland",
    },
    {
      code: "760",
      ar: "سوريا",
      en: "Syrian Arab Republic",
    },
    {
      code: "158",
      ar: "ج. الصين (تايوان)",
      en: "Taiwan, Province of China",
    },
    {
      code: "762",
      ar: "طاجكستان",
      en: "Tajikistan",
    },
    {
      code: "834",
      ar: "تنزانيا",
      en: "Tanzania, United Republic of",
    },
    {
      code: "764",
      ar: "تايلاند",
      en: "Thailand",
    },
    {
      code: "626",
      ar: "تيمور الشرقية",
      en: "Timor-Leste",
    },
    {
      code: "768",
      ar: "توغو",
      en: "Togo",
    },
    {
      code: "772",
      ar: "طوكيلاو",
      en: "Tokelau",
    },
    {
      code: "776",
      ar: "تونجا",
      en: "Tonga",
    },
    {
      code: "780",
      ar: "ترينادا وتوباجو",
      en: "Trinidad and Tobago",
    },
    {
      code: "788",
      ar: "تونس​",
      en: "Tunisia",
    },
    {
      code: "792",
      ar: "تركيا",
      en: "Turkey",
    },
    {
      code: "795",
      ar: "تركمانستان",
      en: "Turkmenistan",
    },
    {
      code: "796",
      ar: "جزرالترك وكايكو",
      en: "Turks and Caicos Islands",
    },
    {
      code: "798",
      ar: "توفالو",
      en: "Tuvalu",
    },
    {
      code: "800",
      ar: "اوغندا",
      en: "Uganda",
    },
    {
      code: "804",
      ar: "اوكرانيا",
      en: "Ukraine",
    },
    {
      code: "784",
      ar: "الامارات",
      en: "United Arab Emirates",
    },
    {
      code: "826",
      ar: "بريطانيا",
      en: "United Kingdom",
    },
    {
      code: "840",
      ar: "الولايات المتحدة",
      en: "United States of America",
    },
    {
      code: "858",
      ar: "الاوروجواي",
      en: "Uruguay",
    },
    {
      code: "860",
      ar: "اوزبكستان",
      en: "Uzbekistan",
    },
    {
      code: "548",
      ar: "فانواتو",
      en: "Vanuatu",
    },
    {
      code: "862",
      ar: "فنيزويلا",
      en: "Venezuela (Bolivarian Republic of)",
    },
    {
      code: "704",
      ar: "فيتنام",
      en: "Viet Nam",
    },
    {
      code: "092",
      ar: "فرجين البريطانية",
      en: "Virgin Islands (British)",
    },
    {
      code: "850",
      ar: "جزر فيرجن",
      en: "Virgin Islands (U.S.)",
    },
    {
      code: "876",
      ar: "جزر والس​وفوتونا",
      en: "Wallis and Futuna",
    },
    {
      code: "732",
      ar: "الصحراء الغربية",
      en: "Western Sahara",
    },
    {
      code: "886",
      ar: "اليمن",
      en: "Yemen",
    },
    {
      code: "894",
      ar: "زامبيا",
      en: "Zambia",
    },
    {
      code: "716",
      ar: "زمبوابوي",
      en: "Zimbabwe",
    },
    {
      code: "248",
      ar: "جزيرة أولند",
      en: "Aland Islands",
    },
    {
      code: "991",
      ar: "Kosovo",
      en: "Kosovo",
    },
    {
      code: "1",
      ar: "Travel Document",
      en: "Travel Document",
    },
  ];
  const handleChangeShowPopup = (e) => {
    if (showRequestPopup === false) {
      refClickedItem.current = e.currentTarget.id;
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
      //fetchData();
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "visible";
      }
    }

    setShowRequestPopup(!showRequestPopup);
  };

  return (
    <div>
      {loadingMarkup}
      <DataTable
        columnContentTypes={["text", "text", "text", "text"]}
        headings={[
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Reference No.</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Name</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Document Number</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Email</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>
              {props.selectedTab == "3" ? "Hayya Number" : "Role"}
            </div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>
              {props.selectedTab == "0" || props.selectedTab == "3"
                ? "Arrival"
                : ""}
            </div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>
              {props.selectedTab == "1" || props.selectedTab == "4"
                ? "Nationality"
                : ""}
            </div>
          </TextStyle>,
          
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Organisation</div>
          </TextStyle>,
          <TextStyle> 
            <div style={{ padding: "1.6rem" }}>Organisation Country</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>
              {props.selectedTab !== "3"
                ? "Time of Registration"
                : "Hayya Date"}
            </div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Type</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Approval Status</div>
          </TextStyle>, 
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>
              {props.selectedTab === "3" ? "Channel" : ""}
            </div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}></div>
          </TextStyle>,
          <TextStyle>
            <div style={{ float: "right", marginRight: "4rem" }}>
              <img
                className="filterImg"
                src={!filterClicked ? filterGrey : filterOrange}
                onClick={handleFilter}
              ></img>
            </div>
          </TextStyle>,
        ]}
        rows={items}
        // sortable={[true, true, true, true]}
        defaultSortDirection="descending"
        initialSortColumnIndex={0}
        onSort={handleSort}
        verticalAlign="middle"
        style={{ color: "red" }}
        // hoverable={false}
      />
      {requestTotal >= 1 && (
        <div>
          <div className="totalDiv">Total: {requestTotal}</div>

          {totalPages >= 2 && (
            <div style={{ float: "right" }}>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  size="large"
                />
              </div>
            </div>
          )}
        </div>
      )}

      <Modal open={activeEmail}>
        <Modal.Section>
          <FormLayout>
            <div className="popupGreyText">Type your message here</div>
            <TextField
              multiline={4}
              value={emailMessageValue}
              onChange={handleChangeEmailMessage}
              error={emailMessageError}
            ></TextField>
          </FormLayout>

          <div
            className="rowDiv"
            style={{ float: "right", marginTop: "3rem", marginBottom: "2rem" }}
          >
            <button
              className={`button ${isSendingEmail ? "buttonDisabled" : "buttonApprove"
                }`}
              onClick={handleSend}
            >
              Send
            </button>
            <button className="button buttonEmail" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </Modal.Section>
      </Modal>
      <Modal open={activeAreYouSure}>
        <Modal.Section>
          <div
            className="popupBlackText"
            style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
          >
            {questionValue}
          </div>
          {reference.current.split("_")[1] == "reject" && (
            <FormLayout>
              <div className="popupGreyText">Reason of Rejection</div>
              <TextField
                multiline={4}
                value={reasonOfRejectionValue}
                onChange={handleChangeReasonOfRejection}
              ></TextField>
            </FormLayout>
          )}
          <div
            className="rowDiv"
            style={{ float: "right", marginTop: "3rem", marginBottom: "2rem" }}
          >
            <button className="button buttonApprove" onClick={handleYes}>
              Yes
            </button>
            <button className="button buttonEmail" onClick={handleNo}>
              No
            </button>
          </div>
        </Modal.Section>
      </Modal>
      {emtyState}
      <Modal open={popupActive} loading={true}></Modal>
      {showRequestPopup && (
        <RequestPopup
          handleChangeShowPopup={handleChangeShowPopup}
          request={refClickedItem.current}
          selectedTab={props.selectedTab}
        />
      )}
      {toastMarkup}
    </div>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findArrayElementByCode(array, id) {
    return array.find((element, index) => {
      return parseInt(element.code) === parseInt(id);
    });
  }
  function findArrayElementByName(array, name) {
    return array.find((element, index) => {
      return element.en === name;
    });
  }
};

export default TableOfrequests;
