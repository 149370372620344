import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import {
  DataTable,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Tabs,
  Layout,
  Button,
  Icon,
} from "@shopify/polaris";
import email from "../Assets/Images/email.svg";
import approve from "../Assets/Images/approve.svg";
import reject from "../Assets/Images/reject.svg";
import approvedDisabledImage from "../Assets/Images/approve-grey.svg";
import rejectedDisabledImage from "../Assets/Images/reject-grey.svg";
import resendDisabledImage from "../Assets/Images/resend-grey.svg";
import download from "../Assets/Images/download.svg";
import forward from "../Assets/Images/forward.svg";
import sync from "../Assets/Images/sync.svg";
import resend from "../Assets/Images/resend.svg";
import syncDisabledImage from "../Assets/Images/sync-grey.svg";
import { adminRole } from "../Helpers";
import blueBgd from "../Assets/Images/blue-bgd.png";
import {
  ReplayMinor,
  RefreshMinor
} from "@shopify/polaris-icons";
const ActionButtons = (props) => {
  const [rejectClicked, setRejectClicked] = useState(false);
  const [approveAndRejectionEnabled, setApproveAndRejectionEnabled] =
    useState(false);

  useEffect(() => {
    let boolApproveAndRejection = false;

    if (
      props.status === "sc" &&
      props?.clickedItem?.status.toLowerCase() !== "rejected_sc" &&
      props?.clickedItem?.status.toLowerCase() !== "approved_sc"
    ) {
      if (
        props?.clickedItem?.status.toLowerCase() === "new" ||
        props?.clickedItem?.status.toLowerCase() === "pending" ||
        props?.clickedItem?.status.toLowerCase() === "updated"
        // props?.clickedItem?.status_level.toLowerCase() === "sc"
      ) {
        boolApproveAndRejection = true;
      } else {
        boolApproveAndRejection = false;
      }
    }
    if (props.status === "gco") {
      if (
        props?.clickedItem?.status_level.toLowerCase() === "gco" &&
        // props?.clickedItem?.accreditation_id !== 1 &&
        props?.clickedItem?.status.toLowerCase() !== "rejected_gco" &&
        props?.clickedItem?.status.toLowerCase() !== "approved_gco"
      ) {
        boolApproveAndRejection = true;
      } else {
        boolApproveAndRejection = false;
      }
    }

    if (props.status === "qna") {
      if (
        props?.clickedItem?.status.toLowerCase() === "approved_gco" &&
        // props?.clickedItem?.accreditation_id !== 1 &&
        props?.clickedItem?.status.toLowerCase() !== "rejected_qna" &&
        props?.clickedItem?.status.toLowerCase() !== "approved_qna"
      ) {
        boolApproveAndRejection = true;
      } else {
        boolApproveAndRejection = false;
      }
    }

    setApproveAndRejectionEnabled(boolApproveAndRejection);
  }, [props]);

  return (
    <Layout.Section className="">
      {window.location.href.indexOf("/admin/users/completed-applications") ===
        -1 &&
        window.location.href.indexOf("/admin/users/incomplete-applications") ===
          -1 &&
        adminRole() !== "Viewer" && (
          <div className="request-action-btns Polaris-FormLayout">
            {props.status === "sc" &&
              (!approveAndRejectionEnabled ? (
                <div className="Polaris-FormLayout__Item">
                  <button className="button buttonDisabled ms-5">
                    {/* <img
                      src={approvedDisabledImage}
                      style={{
                        marginRight: "10px",
                        width: "20px",
                        height: "20px",
                      }}
                    /> */}
                    <span>Approve</span>
                  </button>
                </div>
              ) : (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="button buttonApprove ms-5"
                    id={JSON.stringify(props.clickedItem)}
                    onClick={props.handleRowApprove}
                  >
                    {/* <img
                      src={approve}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      id="approveImg"
                    /> */}
                    <span>Approve</span>
                  </button>
                </div>
              ))}

            {props.status === "sc" &&
              (props?.clickedItem?.status.toLowerCase() === "rejected_sc" ? (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="buttonReject button reject ms-5"
                    onClick={props.handleRowUnreject}
                    id={JSON.stringify(props.clickedItem)}
                  >
                    {/* <img
                      src={reject}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      id="rejectImg"
                    /> */}
                    <span>Unreject</span>
                  </button>
                </div>
              ) : props.status === "sc" &&
                props?.clickedItem?.status.toLowerCase() === "new" ? (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="buttonReject button reject ms-5"
                    onClick={props.handleRowReject}
                    id={JSON.stringify(props.clickedItem)}
                  >
                    {/* <img
                      src={reject}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      id="rejectImg"
                    /> */}
                    <span>Reject</span>
                  </button>
                </div>
              ) : !approveAndRejectionEnabled ? (
                <div className="Polaris-FormLayout__Item">
                  <button className="button buttonDisabled ms-5">
                    {/* <img
                      src={rejectedDisabledImage}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                    /> */}
                    <span>Reject</span>
                  </button>
                </div>
              ) : (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="buttonReject button reject ms-5"
                    onClick={props.handleRowReject}
                    id={JSON.stringify(props.clickedItem)}
                  >
                    {/* <img
                      src={reject}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      id="rejectImg"
                    /> */}
                    <span>Reject</span>
                  </button>
                </div>
              ))}

            {props.status === "gco" &&
              (!approveAndRejectionEnabled ? (
                <div className="Polaris-FormLayout__Item">
                  <button className="button buttonDisabled ms-5">
                    {/* <img
                      src={approvedDisabledImage}
                      style={{
                        marginRight: "10px",
                        width: "20px",
                        height: "20px",
                      }}
                    /> */}
                    <span>Approve</span>
                  </button>
                </div>
              ) : (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="button buttonApprove ms-5"
                    id={JSON.stringify(props.clickedItem)}
                    onClick={props.handleRowApprove}
                  >
                    {/* <img
                      src={approve}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      id="approveImg"
                    /> */}
                    <span>Approve</span>
                  </button>
                </div>
              ))}

            {props.status === "gco" &&
              (!approveAndRejectionEnabled ? (
                <Fragment>
                  {props?.clickedItem?.status.toLowerCase() ===
                  "rejected_gco" ? (
                    <div className="Polaris-FormLayout__Item">
                      <button
                        className="buttonReject button reject ms-5"
                        onClick={props.handleRowUnreject}
                        id={JSON.stringify(props.clickedItem)}
                      >
                        {/* <img
                          src={reject}
                          style={{
                            marginRight: 10,
                            width: "20px",
                            height: "20px",
                          }}
                          id="rejectImg"
                        /> */}
                        <span>Unreject</span>
                      </button>
                    </div>
                  ) : (
                    <div className="Polaris-FormLayout__Item">
                      <button className="button buttonDisabled ms-5">
                        {/* <img
                          src={rejectedDisabledImage}
                          style={{
                            marginRight: 10,
                            width: "20px",
                            height: "20px",
                          }}
                        /> */}
                        <span>Reject</span>
                      </button>
                    </div>
                  )}
                </Fragment>
              ) : (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="buttonReject button reject ms-5"
                    onClick={props.handleRowReject}
                    id={JSON.stringify(props.clickedItem)}
                  >
                    {/* <img
                      src={reject}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      id="rejectImg"
                    /> */}
                    <span>Reject</span>
                  </button>
                </div>
              ))}

            {props.status === "qna" &&
              (!approveAndRejectionEnabled ? (
                <div className="Polaris-FormLayout__Item">
                  <button className="button buttonDisabled ms-5">
                    {/* <img
                      src={approvedDisabledImage}
                      style={{
                        marginRight: "10px",
                        width: "20px",
                        height: "20px",
                      }}
                    /> */}
                    <span>Approve</span>
                  </button>
                </div>
              ) : (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="button buttonApprove ms-5"
                    id={JSON.stringify(props.clickedItem)}
                    onClick={props.handleRowApprove}
                  >
                    {/* <img
                      src={approve}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      id="approveImg"
                    /> */}
                    <span>Approve</span>
                  </button>
                </div>
              ))}

            {props.status === "qna" &&
              (!approveAndRejectionEnabled ? (
                <Fragment>
                  {props?.clickedItem?.status.toLowerCase() ===
                  "rejected_qna" ? (
                    <div className="Polaris-FormLayout__Item">
                      <button
                        className="buttonReject button reject ms-5"
                        onClick={props.handleRowUnreject}
                        id={JSON.stringify(props.clickedItem)}
                      >
                        {/* <img
                          src={reject}
                          style={{
                            marginRight: 10,
                            width: "20px",
                            height: "20px",
                          }}
                          id="rejectImg"
                        /> */}
                        <span>Unreject</span>
                      </button>
                    </div>
                  ) : (
                    <div className="Polaris-FormLayout__Item">
                      <button className="button buttonDisabled ms-5">
                        {/* <img
                          src={rejectedDisabledImage}
                          style={{
                            marginRight: 10,
                            width: "20px",
                            height: "20px",
                          }}
                        /> */}
                        <span>Reject</span>
                      </button>
                    </div>
                  )}
                </Fragment>
              ) : (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="buttonReject button reject ms-5"
                    onClick={props.handleRowReject}
                    id={JSON.stringify(props.clickedItem)}
                  >
                    {/* <img
                      src={reject}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      id="rejectImg"
                    /> */}
                    <span>Reject</span>
                  </button>
                </div>
              ))}

            {window.location.href.indexOf("/admin/requests/gco-requests") ===
              -1 &&
              window.location.href.indexOf("/admin/requests/imo-requests") ===
                -1 &&
              window.location.href.indexOf("/admin/requests/qna-requests") ===
                -1 && (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className="button buttonEmail ms-5"
                    onClick={props.handleEmail}
                  >
                    {/* <img
                      src={email}
                      style={{ marginRight: 4, width: "20px", height: "20px" }}
                      id="emailImg"
                    /> */}
                    <span>Request Update</span>
                  </button>
                </div>
              )}

            {/* <div className="Polaris-FormLayout__Item">
              <button
                className="button buttonForward ms-5"
                onClick={() =>
                  props.clickedItem?.email && window.open(`mailto:`)
                }
              >
                <img
                  src={forward}
                  style={{ marginRight: 10, width: "20px", height: "20px" }}
                  id="forwardImg"
                />
                <span>Forward</span>
              </button>
            </div> */}

            {
              <div className="Polaris-FormLayout__Item">
                <button
                  className="buttonDownload button ms-5"
                  onClick={props.handleDownloadAll}
                >
                  {/* <img
                    src={download}
                    style={{ marginRight: 10, width: "20px", height: "20px" }}
                    className="credentialsImage"
                    id="downloadImg"
                  /> */}
                  <span>Download</span>
                </button>
              </div>
            }

            {props.status === "hayya" && props?.clickedItem?.hayya_status === "Approved" && (
              <div className="Polaris-FormLayout__Item">
                <button
                  className="buttonDownload button ms-5"
                  onClick={props.handleExportEntryPermit}
                >
                  {/* <img
                    src={download}
                    style={{ marginRight: 10, width: "20px", height: "20px" }}
                    className="credentialsImage"
                    id="downloadImg"
                  /> */}
                  <span>Export Permit</span>
                </button>
              </div>
            )}

            {props.status === "hayya" && (
              <div className="Polaris-FormLayout__Item">
                <button
                  className={`buttonSync button ms-5`}
                  onClick={props.handleMoiSync}
                >
                  {/* <img
                    src={sync}
                    style={{ marginRight: 10, width: "20px", height: "20px" }}
                    className="credentialsImage"
                    id="downloadImg"
                  /> */}
                  <Icon source={RefreshMinor}></Icon>
                  <span>Sync With Moi</span>
                </button>
              </div>
            )}

            {props.status === "hayya" && (
              <div className="Polaris-FormLayout__Item">
                <button
                  className={`buttonResend button ms-5`}
                  onClick={props.handleGetFanId}
                >
                  {/* <img
                    src={resend}
                    style={{ marginRight: 10, width: "20px", height: "20px" }}
                    className="credentialsImage"
                    id="downloadImg"
                  /> */}
                  <span>Get Info</span>
                </button>
              </div>
            )}

            {props.status === "hayya" &&
              props?.clickedItem &&
              (props?.clickedItem?.hayya_fanid_number || // add  this for records having fanid but no data in hayya_response
                props?.clickedItem?.hayya_response?.response?.data
                  ?.fanIdNo) && (
                <div className="Polaris-FormLayout__Item">
                  <button
                    className={`buttonSync button ms-5`}
                    onClick={props.handleSync}
                  >
                    {/* <img
                      src={sync}
                      style={{ marginRight: 10, width: "20px", height: "20px" }}
                      className="credentialsImage"
                      id="downloadImg"
                    /> */}
                    <span>Refresh Status</span>
                  </button>
                </div>
              )}

            {props.status === "hayya" &&
              (props?.clickedItem &&
              props?.clickedItem?.hayya_response?.response?.data?.fanIdNo ? (
                <React.Fragment>
                  <div className="Polaris-FormLayout__Item">
                    <button
                      id="update"
                      className={`buttonResend button ms-5`}
                      onClick={props.handleResend}
                    >
                      {/* <img
                        src={resend}
                        style={{
                          marginRight: 10,
                          width: "20px",
                          height: "20px",
                        }}
                        className="credentialsImage"
                        id="downloadImg"
                      /> */}
                      <Icon source={RefreshMinor}></Icon>
                      <span>
                        Sync with MOI and Update
                        {/* {(props?.clickedItem?.hayya_status === "New" &&
                    !props?.clickedItem?.hayya_response?.response?.data
                      ?.fanIdNo) ||
                  (props?.clickedItem?.hayya_status === "400" &&
                    !props?.clickedItem?.hayya_response?.response?.data
                      ?.fanIdNo)
                    ? "Send New"
                    : "Update"} */}
                      </span>
                    </button>
                  </div>
                  <div className="Polaris-FormLayout__Item">
                    <button
                      className={`buttonResend button ms-5`}
                      onClick={props.handleUpdateLiferay}
                    >
                      {/* <img
                        src={resend}
                        style={{
                          marginRight: 10,
                          width: "20px",
                          height: "20px",
                        }}
                        className="credentialsImage"
                        id="downloadImg"
                      /> */}
                      <Icon source={ReplayMinor}></Icon>

                      <span>Force Update</span>
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <Fragment>
                  <div className="Polaris-FormLayout__Item">
                    <button
                      className={`buttonResend button ms-5`}
                      onClick={props.handleResend}
                      id="send"
                    >
                      {/* <img
                        src={resend}
                        style={{
                          marginRight: 10,
                          width: "20px",
                          height: "20px",
                        }}
                        className="credentialsImage"
                        id="downloadImg"
                      /> */}
                      <span>Send New</span>
                    </button>
                  </div>
                  <div className="Polaris-FormLayout__Item">
                    <button
                      className={`buttonResend button ms-5`}
                      onClick={props.handleSendLiferay}
                    >
                      {/* <img
                        src={resend}
                        style={{
                          marginRight: 10,
                          width: "20px",
                          height: "20px",
                        }}
                        className="credentialsImage"
                        id="downloadImg"
                      /> */}
                      <span>Send Original</span>
                    </button>
                  </div>
                </Fragment>
              ))}

            {false && props.status === "hayya" && (
              <div className="Polaris-FormLayout__Item">
                <button
                  className={`buttonResend button ms-5`}
                  onClick={props.handleUpdateFanId}
                >
                  {/* <img
                    src={resend}
                    style={{ marginRight: 8, width: "20px", height: "20px" }}
                    className="credentialsImage"
                    id="downloadImg"
                  /> */}
                  {/* <span>Update Fan ID</span> */}
                  <span>Update Hayya Number</span>
                </button>
              </div>
            )}
          </div>
        )}

      {(window.location.href.indexOf("/admin/users/completed-applications") >
        -1 ||
        window.location.href.indexOf("/admin/users/incomplete-applications") >
          -1) &&
        adminRole() !== "Viewer" && (
          <div className="Polaris-FormLayout mb-5 px-3 d-flex">
            {window.location.href.indexOf(
              "/admin/users/completed-applications"
            ) > -1 && (
              <div className="">
                <button
                  className="buttonDelete button ms-5"
                  onClick={props.handleDeleteApplication}
                >
                  {/* <img
                 src={reject}
                style={{ marginRight: 10, width: "20px", height: "20px" }}
                className="credentialsImage"
                id="downloadImg"
              /> */}
                  <span>Delete Application</span>
                </button>
              </div>
            )}

            <div className="">
              <button
                className="buttonDelete button ms-5"
                onClick={props.handleDeleteAccount}
              >
                {/* <img
                src={reject}
                style={{ marginRight: 10, width: "20px", height: "20px" }}
                className="credentialsImage"
                id="downloadImg"
              /> */}
                <span>Delete User</span>
              </button>
            </div>
          </div>
        )}
      {props?.clickedItem?.hayya_status === "Approved" &&
        parseInt(props?.clickedItem?.accreditation_id) === 2 && (
          <p className="blueBgd ms-4"></p>
        )}
      {props?.clickedItem?.hayya_status === "Approved" &&
        parseInt(props?.clickedItem?.accreditation_id) === 3 && (
          <p className="purpleBgd ms-4"></p>
        )}
      {/* <div
        className="ms-3"
        style={{
          marginTop: props?.clickedItem?.hayya_status !== "Approved" && "5rem",
        }}
      >
        {props?.clickedItem?.fifa_accreditation_number && (
          <div>
            <div className="popupGreyTextSmall">FIFA Accreditation Number</div>
            <div className="popupBlackTextSmall" style={{ color: "#005391" }}>
              {props?.clickedItem?.fifa_accreditation_number}
            </div>
          </div>
        )}
        {props?.clickedItem?.additional_data?.date_of_arrival && (
          <div>
            <div className="popupGreyTextSmall">Indicative Date of Arrival</div>
            <div className="popupBlackTextSmall">
              {props?.clickedItem?.additional_data?.date_of_arrival}
            </div>
          </div>
        )}
        {props?.clickedItem?.additional_data?.date_of_departure && (
          <div>
            <div className="popupGreyTextSmall">
              Indicative Date of Departure
            </div>
            <div className="popupBlackTextSmall">
              {props?.clickedItem?.additional_data?.date_of_departure}
            </div>
          </div>
        )}
        {props?.clickedItem?.organisation?.role?.code && (
          <div>
            <div className="popupGreyTextSmall">Venues</div>
            <div className="popupBlackTextSmall">
              {props?.clickedItem?.organisation?.role?.code}
            </div>
          </div>
        )}
        {props?.clickedItem &&
          props?.clickedItem?.hayya_response?.response?.status === 200 &&
          props?.clickedItem?.hayya_response?.response?.data.applicationId && (
            <div>
              <div className="popupGreyTextSmall">Application ID</div>
              <div className="popupBlackTextSmall">
                {
                  props?.clickedItem?.hayya_response?.response?.data
                    .applicationId
                }
              </div>
            </div>
          )}
        {props?.clickedItem &&
          props?.clickedItem?.hayya_response?.response?.data?.fanIdNo && (
            <div>
              <div className="popupGreyTextSmall">Entry Reference Number</div>

              <div className="popupBlackTextSmall">
                {props?.clickedItem?.hayya_response?.response?.data.fanIdNo}
              </div>
            </div>
          )}
        {props?.clickedItem &&
          props?.clickedItem?.hayya_response?.response?.data?.hayyaNo && (
            <div>
              <div className="popupGreyTextSmall">Hayya Number</div>

              <div className="popupBlackTextSmall">
                {props?.clickedItem?.hayya_response?.response?.data.hayyaNo}
              </div>
            </div>
          )}
        {props?.clickedItem?.hayya_response &&
          props?.clickedItem?.status === 400 && (
            <div>
              <div className="popupGreyTextSmall">Message</div>
              <div className="popupBlackTextSmall">
                {props?.clickedItem?.message}
              </div>
            </div>
          )}
        {props?.clickedItem?.hayya_status === "Data Error" &&
          props?.clickedItem?.hayya_response?.response?.data?.fanIdNo &&
          props?.clickedItem?.hayya_message && (
            <div>
              <div className="popupGreyTextSmall">MOI Error</div>
              <div className="popupBlackTextSmall">
                {props?.clickedItem?.hayya_message
                  ?.replaceAll("[", "")
                  .replaceAll("]", "")}
              </div>
            </div>
          )}

        {
          props?.clickedItem?.hayya_logs_check_eligibility_message && (
            <div>
              <div className="popupGreyTextSmall">Eligibility Error</div>
              <div className="popupBlackTextSmall">
                {props?.clickedItem?.hayya_logs_check_eligibility_message
                  ?.replaceAll("[", "")
                  .replaceAll("]", "")}
              </div>
            </div>
          )
        }

        {props?.clickedItem?.hayya_status === "Rejected" &&
          props?.clickedItem?.hayya_response?.response?.data?.fanIdNo &&
          props?.clickedItem?.hayya_message && (
            <div>
              <div className="popupGreyTextSmall">MOI Rejection Message</div>
              <div className="popupBlackTextSmall">
                {props?.clickedItem?.hayya_message
                  ?.replaceAll("[", "")
                  .replaceAll("]", "")}
              </div>
            </div>
          )}
        {props?.clickedItem?.hayya_status === "400" && (
          <div>
            <div className="popupGreyTextSmall">Hayya Error</div>
            <div className="popupBlackTextSmall">
              {props?.clickedItem?.hayya_response?.response?.message &&
                props?.clickedItem?.hayya_response?.response?.message}
              {!props?.clickedItem?.hayya_response?.response?.message &&
                props?.clickedItem?.hayya_response?.response?.errors &&
                props?.clickedItem?.hayya_response?.response?.errors[
                  Object.keys(
                    props?.clickedItem?.hayya_response?.response?.errors
                  )[0]
                ] &&
                props?.clickedItem?.hayya_response?.response?.errors[
                  Object.keys(
                    props?.clickedItem?.hayya_response?.response?.errors
                  )[0]
                ]}
            </div>
          </div>
        )}
      </div> */}
    </Layout.Section>
  );
};

export default ActionButtons;
